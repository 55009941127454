body[data-v-42f8fe6f]{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 150px;
    margin-bottom: 150px;
}
.m[data-v-42f8fe6f]{
margin-top: 100px;
margin-bottom: 70px;
}
.burgundy[data-v-42f8fe6f] {
    color: rgba(20,92,148,0.9710084717480743);
    font-size: 50px;
    font-weight: 400;
}
.orange[data-v-42f8fe6f]{
    font-size: 45px;
    color: grey;
    font-weight: 400;
}
.card[data-v-42f8fe6f]{
    position: relative;
    width: 390px;
    height: 250px;
    margin: 20px;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    transition: all .2s linear;
    border-radius: 10px;
}
img[data-v-42f8fe6f]{
    width: 100%;
    transition: all .2s linear;
}
.card-content[data-v-42f8fe6f]{
    position: absolute;
    bottom: 3rem;
    right: 100%;
    width: 100%;
    text-align: center;
    text-decoration: none;
    transition: all .2s linear;
    text-decoration: none;
}
a[data-v-42f8fe6f], a[data-v-42f8fe6f]:hover, a[data-v-42f8fe6f]:visited, a[data-v-42f8fe6f]:active, a[data-v-42f8fe6f]:link{
    text-decoration: none;
    color: #FFFFFF;
}
.card-category[data-v-42f8fe6f]{
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 2px;
    padding: 10px;
    margin: 15px;
    color: #000;
    background-color: rgba(255,255,255,.7);
    border-radius: 10px;
}
i[data-v-42f8fe6f]{
    margin-left: 5px;
}
.card[data-v-42f8fe6f]:hover{
      box-shadow: 0 0px 28px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(0,0,0,0.22)
}
.card:hover .card-content[data-v-42f8fe6f]{
    right: 0;
}
.card:hover img[data-v-42f8fe6f]{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
  
  
  
  
  
  