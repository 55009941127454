@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
**[data-v-45d4bc66] {
  box-sizing: border-box;
}
#products.row[data-v-45d4bc66] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#search[data-v-45d4bc66] {
  outline: none;
  border: none;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.product-item[data-v-45d4bc66] {
  height: 400px;
  width: 300px;
}
.card-img-top[data-v-45d4bc66] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.burgundy[data-v-45d4bc66] {
  color: rgba(20,92,148,0.9710084717480743);
  font-size: 50px;
  font-weight: 400;
  text-decoration: none;
}
.orange[data-v-45d4bc66]{
  font-size: 45px;
  color: grey;
  font-weight: 400;
  text-decoration: none;
}
.card-img-top[data-v-45d4bc66] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card[data-v-45d4bc66] {
  max-width: 16rem;
}
.select[data-v-45d4bc66]{
  color: grey;
}
.btn[data-v-45d4bc66] {
  color: grey;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
button[data-v-45d4bc66]{
  border-radius: 30px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}

/* .btn:hover {
 
  background: rgb(235,36,44);
  background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%); 
} */
.fa-search[data-v-45d4bc66] {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 3px;
  padding-right: 20px;
}
.search-nav-item[data-v-45d4bc66] {
  height: 60px;
  margin-top: 150px;
}
nav[data-v-45d4bc66] {
  padding: 0px 100px;
}
.fa-user-o[data-v-45d4bc66],
.fa-shopping-cart[data-v-45d4bc66] {
  font-size: 20px;
  padding: 4px;
}
.form-group[data-v-45d4bc66] {
  margin-bottom: 5px;
}
label[data-v-45d4bc66] {
  padding-left: 10px;
}
.form-group[data-v-45d4bc66]:last-child {
  margin-bottom: 0;
}
h6[data-v-45d4bc66] {
  margin-bottom: 0px;
}
#sort[data-v-45d4bc66] {
  outline: none;
  border: none;
}
.btn[data-v-45d4bc66] {
  border-radius: 50px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn[data-v-45d4bc66]:hover {
  color: grey;
  background-color: rgb(95, 89, 89);
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card-body[data-v-45d4bc66] {
  padding: 8px;
}
.sign[data-v-45d4bc66] {
  width: 25px;
  height: 25px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.discount[data-v-45d4bc66] {
  border: 2px solid red;
  border-radius: 2px;
  width: 65px;
  position: absolute;
  top: 2%;
}
.rounded[data-v-45d4bc66] {
  border-radius: 10px !important;
}
@media (min-width: 1200px) {
#search[data-v-45d4bc66] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-45d4bc66] {
    margin-left: 400px;
    width: 350px;
}
.fa-user-o[data-v-45d4bc66] {
    margin-left: 300px;
}
.text[data-v-45d4bc66] {
    display: none;
}
.fa-user-o[data-v-45d4bc66],
  .fa-shopping-cart[data-v-45d4bc66] {
    font-size: 20px;
    padding-left: 20px;
}
#sidebar[data-v-45d4bc66] {
    width: 30%;
    padding: 20px;
    float: left;
}
#products[data-v-45d4bc66] {
    padding: 10px;
    margin: 0;
}
.card[data-v-45d4bc66] {

    border: none;
}
del[data-v-45d4bc66] {
    padding-right: 2px;
}
.filter[data-v-45d4bc66],
  #mobile-filter[data-v-45d4bc66] {
    display: none;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
#search[data-v-45d4bc66] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-45d4bc66] {
    margin-left: 200px;
    width: 350px;
}
.fa-user-o[data-v-45d4bc66] {
    margin-left: 160px;
}
.text[data-v-45d4bc66] {
    display: none;
}
#products[data-v-45d4bc66] {
    padding: 10px;
    margin: 0;
}
.card[data-v-45d4bc66] {
    border: none;
}
.fa-plus[data-v-45d4bc66],
  .fa-minus[data-v-45d4bc66] {
    font-size: 12px;
}
.sign[data-v-45d4bc66] {
    height: 25px;
}
.price[data-v-45d4bc66] {
    width: 99px;
}
.filter[data-v-45d4bc66],
  #mobile-filter[data-v-45d4bc66] {
    display: none;
}
}
@media (min-width: 768px) and (max-width: 991px) {
#search[data-v-45d4bc66] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-45d4bc66] {
    margin-left: 60px;
    width: 350px;
}
.fa-user-o[data-v-45d4bc66] {
    margin-left: 80px;
}
.text[data-v-45d4bc66] {
    display: none;
}
#sidebar[data-v-45d4bc66] {
    width: 35%;
    padding: 20px;
    float: left;
}
#products[data-v-45d4bc66] {
    width: 65%;
    padding: 10px;
    margin: 0;
    float: right;
}
.card[data-v-45d4bc66] {
    border: none;
}
.filter[data-v-45d4bc66],
  #mobile-filter[data-v-45d4bc66] {
    display: none;
}
}
@media (min-width: 576px) and (max-width: 767px) {
.text[data-v-45d4bc66] {
    display: none;
}
.search-nav-item[data-v-45d4bc66] {
    margin-left: 35px;
    width: 270px;
}
#search[data-v-45d4bc66] {
    width: 240px;
    padding: 5px;
    padding-left: 20px;
}
.fa-search[data-v-45d4bc66] {
    padding: 3px;
    font-size: 18px;
}
#sidebar[data-v-45d4bc66] {
    width: 40%;
    padding: 20px;
    float: left;
}
#products[data-v-45d4bc66] {
    padding: 10px;
    margin: 0;
}
.card[data-v-45d4bc66] {
    border: none;
}
#off[data-v-45d4bc66] {
    padding-left: 2px;
}
#sorting span[data-v-45d4bc66],
  #res[data-v-45d4bc66] {
    font-size: 14px;
}
.filter[data-v-45d4bc66],
  #mobile-filter[data-v-45d4bc66] {
    display: none;
}
}
@media (max-width: 575px) {
.search-nav-item[data-v-45d4bc66] {
    margin: 0;
    width: 100%;
    margin-top: 10px;
}
#search[data-v-45d4bc66] {
    width: 80%;
    padding-left: 10px;
    padding-right: 10px;
}
.fa-search[data-v-45d4bc66] {
    width: 80%;
    padding: 10px;
    font-size: 18px;
}
#sidebar[data-v-45d4bc66] {
    display: none;
}
.filter[data-v-45d4bc66] {
    margin-left: 70%;
    margin-top: 2%;
}
#sorting[data-v-45d4bc66],
  #res[data-v-45d4bc66] {
    font-size: 12px;
    margin-top: 2%;
}
}

**[data-v-b9abd7d8] {
  box-sizing: border-box;
}
#products.row[data-v-b9abd7d8] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#search[data-v-b9abd7d8] {
  outline: none;
  border: none;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.product-item[data-v-b9abd7d8] {
  height: 400px;
  width: 300px;
}
.card-img-top[data-v-b9abd7d8] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.burgundy[data-v-b9abd7d8] {
  color: rgba(20,92,148,0.9710084717480743);
  font-size: 50px;
  font-weight: 400;
  text-decoration: none;
}
.orange[data-v-b9abd7d8]{
  font-size: 45px;
  color: grey;
  font-weight: 400;
  text-decoration: none;
}
.card-img-top[data-v-b9abd7d8] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card[data-v-b9abd7d8] {
  max-width: 16rem;
}
.select[data-v-b9abd7d8]{
  color: grey;
}
.btn[data-v-b9abd7d8] {
  color: grey;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
button[data-v-b9abd7d8]{
  border-radius: 30px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}

/* .btn:hover {
 
  background: rgb(235,36,44);
  background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%); 
} */
.fa-search[data-v-b9abd7d8] {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 3px;
  padding-right: 20px;
}
.search-nav-item[data-v-b9abd7d8] {
  height: 60px;
  margin-top: 150px;
}
nav[data-v-b9abd7d8] {
  padding: 0px 100px;
}
.fa-user-o[data-v-b9abd7d8],
.fa-shopping-cart[data-v-b9abd7d8] {
  font-size: 20px;
  padding: 4px;
}
.form-group[data-v-b9abd7d8] {
  margin-bottom: 5px;
}
label[data-v-b9abd7d8] {
  padding-left: 10px;
}
.form-group[data-v-b9abd7d8]:last-child {
  margin-bottom: 0;
}
h6[data-v-b9abd7d8] {
  margin-bottom: 0px;
}
#sort[data-v-b9abd7d8] {
  outline: none;
  border: none;
}
.btn[data-v-b9abd7d8] {
  border-radius: 50px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn[data-v-b9abd7d8]:hover {
  color: grey;
  background-color: rgb(95, 89, 89);
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card-body[data-v-b9abd7d8] {
  padding: 8px;
}
.sign[data-v-b9abd7d8] {
  width: 25px;
  height: 25px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.discount[data-v-b9abd7d8] {
  border: 2px solid red;
  border-radius: 2px;
  width: 65px;
  position: absolute;
  top: 2%;
}
.rounded[data-v-b9abd7d8] {
  border-radius: 10px !important;
}
@media (min-width: 1200px) {
#search[data-v-b9abd7d8] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-b9abd7d8] {
    margin-left: 400px;
    width: 350px;
}
.fa-user-o[data-v-b9abd7d8] {
    margin-left: 300px;
}
.text[data-v-b9abd7d8] {
    display: none;
}
.fa-user-o[data-v-b9abd7d8],
  .fa-shopping-cart[data-v-b9abd7d8] {
    font-size: 20px;
    padding-left: 20px;
}
#sidebar[data-v-b9abd7d8] {
    width: 30%;
    padding: 20px;
    float: left;
}
#products[data-v-b9abd7d8] {
    padding: 10px;
    margin: 0;
}
.card[data-v-b9abd7d8] {

    border: none;
}
del[data-v-b9abd7d8] {
    padding-right: 2px;
}
.filter[data-v-b9abd7d8],
  #mobile-filter[data-v-b9abd7d8] {
    display: none;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
#search[data-v-b9abd7d8] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-b9abd7d8] {
    margin-left: 200px;
    width: 350px;
}
.fa-user-o[data-v-b9abd7d8] {
    margin-left: 160px;
}
.text[data-v-b9abd7d8] {
    display: none;
}
#products[data-v-b9abd7d8] {
    padding: 10px;
    margin: 0;
}
.card[data-v-b9abd7d8] {
    border: none;
}
.fa-plus[data-v-b9abd7d8],
  .fa-minus[data-v-b9abd7d8] {
    font-size: 12px;
}
.sign[data-v-b9abd7d8] {
    height: 25px;
}
.price[data-v-b9abd7d8] {
    width: 99px;
}
.filter[data-v-b9abd7d8],
  #mobile-filter[data-v-b9abd7d8] {
    display: none;
}
}
@media (min-width: 768px) and (max-width: 991px) {
#search[data-v-b9abd7d8] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-b9abd7d8] {
    margin-left: 60px;
    width: 350px;
}
.fa-user-o[data-v-b9abd7d8] {
    margin-left: 80px;
}
.text[data-v-b9abd7d8] {
    display: none;
}
#sidebar[data-v-b9abd7d8] {
    width: 35%;
    padding: 20px;
    float: left;
}
#products[data-v-b9abd7d8] {
    width: 65%;
    padding: 10px;
    margin: 0;
    float: right;
}
.card[data-v-b9abd7d8] {
    border: none;
}
.filter[data-v-b9abd7d8],
  #mobile-filter[data-v-b9abd7d8] {
    display: none;
}
}
@media (min-width: 576px) and (max-width: 767px) {
.text[data-v-b9abd7d8] {
    display: none;
}
.search-nav-item[data-v-b9abd7d8] {
    margin-left: 35px;
    width: 270px;
}
#search[data-v-b9abd7d8] {
    width: 240px;
    padding: 5px;
    padding-left: 20px;
}
.fa-search[data-v-b9abd7d8] {
    padding: 3px;
    font-size: 18px;
}
#sidebar[data-v-b9abd7d8] {
    width: 40%;
    padding: 20px;
    float: left;
}
#products[data-v-b9abd7d8] {
    padding: 10px;
    margin: 0;
}
.card[data-v-b9abd7d8] {
    border: none;
}
#off[data-v-b9abd7d8] {
    padding-left: 2px;
}
#sorting span[data-v-b9abd7d8],
  #res[data-v-b9abd7d8] {
    font-size: 14px;
}
.filter[data-v-b9abd7d8],
  #mobile-filter[data-v-b9abd7d8] {
    display: none;
}
}
@media (max-width: 575px) {
.search-nav-item[data-v-b9abd7d8] {
    margin: 0;
    width: 100%;
    margin-top: 10px;
}
#search[data-v-b9abd7d8] {
    width: 80%;
    padding-left: 10px;
    padding-right: 10px;
}
.fa-search[data-v-b9abd7d8] {
    width: 80%;
    padding: 10px;
    font-size: 18px;
}
#sidebar[data-v-b9abd7d8] {
    display: none;
}
.filter[data-v-b9abd7d8] {
    margin-left: 70%;
    margin-top: 2%;
}
#sorting[data-v-b9abd7d8],
  #res[data-v-b9abd7d8] {
    font-size: 12px;
    margin-top: 2%;
}
}

body[data-v-9d754e9f] {
  background-color: #eeeeee;
  font-family: "Open Sans", serif;
  font-size: 14px;
}
#row-container.row[data-v-9d754e9f] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#burgundy[data-v-9d754e9f] {
  color: red;
  font-size: 30px;
}
#orange[data-v-9d754e9f],
select[data-v-9d754e9f],
.btn[data-v-9d754e9f] {
  color: grey;
}
.mt-100[data-v-9d754e9f] {
  margin-top: 150px;
}
.filter-group[data-v-9d754e9f] {
  border-bottom: 1px solid #e4e4e4;
}
.title[data-v-9d754e9f] {
  color: grey;
  font-weight: 500;
}
.card[data-v-9d754e9f] {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 4px solid rgba(104, 100, 100, 0.1);
  border-radius: 18px;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.category-link[data-v-9d754e9f] {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
}
.card-header[data-v-9d754e9f] {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 5px;
}
input[type="checkbox"][data-v-9d754e9f], input[type="radio"][data-v-9d754e9f] {
  box-sizing: border-box;
  padding: 0;
  border-radius: 30px;
}
.filter-group .card-header[data-v-9d754e9f] {
  border-bottom: 0;
}
.icon-control[data-v-9d754e9f] {
  margin-top: 6px;
  float: right;
  font-size: 80%;
  color: grey;
}
.list-menu[data-v-9d754e9f] {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.list-menu a[data-v-9d754e9f] {
  color: #343a40;
}
a[data-v-9d754e9f] {
  text-decoration: none !important;
  background-color: transparent;
}
.checkbox-btn[data-v-9d754e9f] {
  position: relative;
}
.checkbox-btn input[data-v-9d754e9f] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-btn input:checked ~ .btn[data-v-9d754e9f] {
  border-color: #3167eb;
  background-color: #3167eb;
  color: #fff;
}
.btn-light[data-v-9d754e9f] {
  display: inline-block;
  font-weight: 600;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #eee;
  border: 1px solid #eee;
  padding: 0.45rem 0.85rem;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 18px;
  outline: none;
}
.btn-light[data-v-9d754e9f]:hover {
  background-color: #fff;
  border-color: #989898;
  outline: none;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn-medium[data-v-9d754e9f] {
  font-size: 15px;
  padding: 10px 22px;
  display: inline-block;
  margin-right: 50px;
  letter-spacing: 2px;
  border: 1px  rgba(20, 92, 148, 1);
  width: 100%;
  outline: none;
  border-radius: 15px;
}
.custom-range[data-v-9d754e9f]::-webkit-slider-thumb {
  background: rgba(20, 92, 148, 1);
}
.custom-range[data-v-9d754e9f]::-moz-range-thumb {
  background: rgba(20, 92, 148, 1);
}
.custom-range[data-v-9d754e9f]::-ms-thumb {
  background: rgba(20, 92, 148, 1);
}
.highlight-button[data-v-9d754e9f]:hover {
  background-color: rgba(20, 92, 148, 1);
  color: #fff;
  outline: none;
}
.custom-control[data-v-9d754e9f] {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

*[data-v-2fb4ecbf] {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body[data-v-2fb4ecbf] {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.serv[data-v-2fb4ecbf]{
  margin-top: 300px;
  padding: 100px;
}
.container[data-v-2fb4ecbf] {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  flex-wrap: wrap;
  padding: 40px 0;
}
.container .card[data-v-2fb4ecbf] {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  margin: 30px;
}
.burgundy[data-v-2fb4ecbf] {
  color: rgba(20,92,148,0.9710084717480743);
  font-size: 50px;
  font-weight: 400;
}
.orange[data-v-2fb4ecbf]{
  font-size: 45px;
  color: grey;
  font-weight: 400;
}
.container .card .box[data-v-2fb4ecbf] {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #e6e6e6;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.container .card:hover .box[data-v-2fb4ecbf] {
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  box-shadow: 0 10px 40px rgba(0 0, 0, 0.2);
  background: rgb(235,36,44);
  background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%);
}
.container .card .box .content[data-v-2fb4ecbf] {
  padding: 20px;
  white-space: pre-wrap;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.container .card .box .content h2[data-v-2fb4ecbf] {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8em;
  color: rgba(0, 0, 0, 0.04);
  transition: 0.6s;
  pointer-events: none;
}
.container .card:hover .box .content h2[data-v-2fb4ecbf] {
  color: rgba(0, 0, 0, 0.1);
}
.container .card .box .content h3[data-v-2fb4ecbf] {
  font-size: 1.8em;
  color: #777;
  z-index: 1;
  transition: 0.5s;
}
.container .card .box .content p[data-v-2fb4ecbf] {
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  color: #777;
  z-index: 1;
  transition: 0.5s;
  white-space: pre-wrap;
}
.container .card:hover .box .content h3[data-v-2fb4ecbf],
.container .card:hover .box .content p[data-v-2fb4ecbf] {
  color: #fff;
}
.container .card .box .content a[data-v-2fb4ecbf] {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: rgba(20, 92, 148, 1);
  margin-top: 15px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.container .card:hover .box .content a[data-v-2fb4ecbf] {
  background: rgba(139, 195, 235, 1);
}
#burgundy[data-v-2fb4ecbf] {
  color: red;
  font-size: 30px;
}
#orange[data-v-2fb4ecbf] {
  color: grey;
}
h3[data-v-2fb4ecbf]{
  margin-bottom: 10px;
}
**[data-v-2fb4ecbf] {
  box-sizing: border-box;
}
#products.row[data-v-2fb4ecbf] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#search[data-v-2fb4ecbf] {
  outline: none;
  border: none;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.product-item[data-v-2fb4ecbf] {
  height: 400px;
  width: 300px;
}
.card-img-top[data-v-2fb4ecbf] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.burgundy[data-v-2fb4ecbf] {
  color: rgba(20,92,148,0.9710084717480743);
  font-size: 50px;
  font-weight: 400;
  text-decoration: none;
}
.orange[data-v-2fb4ecbf]{
  font-size: 45px;
  color: grey;
  font-weight: 400;
  text-decoration: none;
}
.card-img-top[data-v-2fb4ecbf] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card[data-v-2fb4ecbf] {
  max-width: 16rem;
}
.select[data-v-2fb4ecbf]{
  color: grey;
}
.btn[data-v-2fb4ecbf] {
  color: grey;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
button[data-v-2fb4ecbf]{
  border-radius: 30px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}

/* .btn:hover {
 
  background: rgb(235,36,44);
  background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%); 
} */
.fa-search[data-v-2fb4ecbf] {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 3px;
  padding-right: 20px;
}
.search-nav-item[data-v-2fb4ecbf] {
  height: 60px;
  margin-top: 150px;
}
nav[data-v-2fb4ecbf] {
  padding: 0px 100px;
}
.fa-user-o[data-v-2fb4ecbf],
.fa-shopping-cart[data-v-2fb4ecbf] {
  font-size: 20px;
  padding: 4px;
}
.form-group[data-v-2fb4ecbf] {
  margin-bottom: 5px;
}
label[data-v-2fb4ecbf] {
  padding-left: 10px;
}
.form-group[data-v-2fb4ecbf]:last-child {
  margin-bottom: 0;
}
h6[data-v-2fb4ecbf] {
  margin-bottom: 0px;
}
#sort[data-v-2fb4ecbf] {
  outline: none;
  border: none;
}
.btn[data-v-2fb4ecbf] {
  border-radius: 50px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn[data-v-2fb4ecbf]:hover {
  color: grey;
  background-color: rgb(95, 89, 89);
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card-body[data-v-2fb4ecbf] {
  padding: 8px;
}
.sign[data-v-2fb4ecbf] {
  width: 25px;
  height: 25px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.discount[data-v-2fb4ecbf] {
  border: 2px solid red;
  border-radius: 2px;
  width: 65px;
  position: absolute;
  top: 2%;
}
.rounded[data-v-2fb4ecbf] {
  border-radius: 10px !important;
}
@media (min-width: 1200px) {
#search[data-v-2fb4ecbf] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-2fb4ecbf] {
    margin-left: 400px;
    width: 350px;
}
.fa-user-o[data-v-2fb4ecbf] {
    margin-left: 300px;
}
.text[data-v-2fb4ecbf] {
    display: none;
}
.fa-user-o[data-v-2fb4ecbf],
  .fa-shopping-cart[data-v-2fb4ecbf] {
    font-size: 20px;
    padding-left: 20px;
}
#sidebar[data-v-2fb4ecbf] {
    width: 30%;
    padding: 20px;
    float: left;
}
#products[data-v-2fb4ecbf] {
    padding: 10px;
    margin: 0;
}
.card[data-v-2fb4ecbf] {

    border: none;
}
del[data-v-2fb4ecbf] {
    padding-right: 2px;
}
.filter[data-v-2fb4ecbf],
  #mobile-filter[data-v-2fb4ecbf] {
    display: none;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
#search[data-v-2fb4ecbf] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-2fb4ecbf] {
    margin-left: 200px;
    width: 350px;
}
.fa-user-o[data-v-2fb4ecbf] {
    margin-left: 160px;
}
.text[data-v-2fb4ecbf] {
    display: none;
}
#products[data-v-2fb4ecbf] {
    padding: 10px;
    margin: 0;
}
.card[data-v-2fb4ecbf] {
    border: none;
}
.fa-plus[data-v-2fb4ecbf],
  .fa-minus[data-v-2fb4ecbf] {
    font-size: 12px;
}
.sign[data-v-2fb4ecbf] {
    height: 25px;
}
.price[data-v-2fb4ecbf] {
    width: 99px;
}
.filter[data-v-2fb4ecbf],
  #mobile-filter[data-v-2fb4ecbf] {
    display: none;
}
}
@media (min-width: 768px) and (max-width: 991px) {
#search[data-v-2fb4ecbf] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-2fb4ecbf] {
    margin-left: 60px;
    width: 350px;
}
.fa-user-o[data-v-2fb4ecbf] {
    margin-left: 80px;
}
.text[data-v-2fb4ecbf] {
    display: none;
}
#sidebar[data-v-2fb4ecbf] {
    width: 35%;
    padding: 20px;
    float: left;
}
#products[data-v-2fb4ecbf] {
    width: 65%;
    padding: 10px;
    margin: 0;
    float: right;
}
.card[data-v-2fb4ecbf] {
    border: none;
}
.filter[data-v-2fb4ecbf],
  #mobile-filter[data-v-2fb4ecbf] {
    display: none;
}
}
@media (min-width: 576px) and (max-width: 767px) {
.text[data-v-2fb4ecbf] {
    display: none;
}
.search-nav-item[data-v-2fb4ecbf] {
    margin-left: 35px;
    width: 270px;
}
#search[data-v-2fb4ecbf] {
    width: 240px;
    padding: 5px;
    padding-left: 20px;
}
.fa-search[data-v-2fb4ecbf] {
    padding: 3px;
    font-size: 18px;
}
#sidebar[data-v-2fb4ecbf] {
    width: 40%;
    padding: 20px;
    float: left;
}
#products[data-v-2fb4ecbf] {
    padding: 10px;
    margin: 0;
}
.card[data-v-2fb4ecbf] {
    border: none;
}
#off[data-v-2fb4ecbf] {
    padding-left: 2px;
}
#sorting span[data-v-2fb4ecbf],
  #res[data-v-2fb4ecbf] {
    font-size: 14px;
}
.filter[data-v-2fb4ecbf],
  #mobile-filter[data-v-2fb4ecbf] {
    display: none;
}
}
@media (max-width: 575px) {
.search-nav-item[data-v-2fb4ecbf] {
    margin: 0;
    width: 100%;
    margin-top: 10px;
}
#search[data-v-2fb4ecbf] {
    width: 80%;
    padding-left: 10px;
    padding-right: 10px;
}
.fa-search[data-v-2fb4ecbf] {
    width: 80%;
    padding: 10px;
    font-size: 18px;
}
#sidebar[data-v-2fb4ecbf] {
    display: none;
}
.filter[data-v-2fb4ecbf] {
    margin-left: 70%;
    margin-top: 2%;
}
#sorting[data-v-2fb4ecbf],
  #res[data-v-2fb4ecbf] {
    font-size: 12px;
    margin-top: 2%;
}
}

body[data-v-f17b5f38]{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 150px;
    margin-bottom: 150px;
}
.m[data-v-f17b5f38]{
margin-top: 100px;
margin-bottom: 70px;
}
.burgundy[data-v-f17b5f38] {
    color: rgba(20,92,148,0.9710084717480743);
    font-size: 50px;
    font-weight: 400;
}
.orange[data-v-f17b5f38]{
    font-size: 45px;
    color: grey;
    font-weight: 400;
}
.card[data-v-f17b5f38]{
    position: relative;
    width: 390px;
    height: 250px;
    margin: 20px;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    transition: all .2s linear;
    border-radius: 10px;
}
img[data-v-f17b5f38]{
    width: 100%;
    transition: all .2s linear;
}
.card-content[data-v-f17b5f38]{
    position: absolute;
    bottom: 3rem;
    right: 100%;
    width: 100%;
    text-align: center;
    text-decoration: none;
    transition: all .2s linear;
    text-decoration: none;
}
a[data-v-f17b5f38], a[data-v-f17b5f38]:hover, a[data-v-f17b5f38]:visited, a[data-v-f17b5f38]:active, a[data-v-f17b5f38]:link{
    text-decoration: none;
    color: #FFFFFF;
}
.card-category[data-v-f17b5f38]{
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 2px;
    padding: 10px;
    margin: 15px;
    color: #000;
    background-color: rgba(255,255,255,.7);
    border-radius: 10px;
}
i[data-v-f17b5f38]{
    margin-left: 5px;
}
.card[data-v-f17b5f38]:hover{
      box-shadow: 0 0px 28px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(0,0,0,0.22)
}
.card:hover .card-content[data-v-f17b5f38]{
    right: 0;
}
.card:hover img[data-v-f17b5f38]{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
  
  
  
  
  
  
footer[data-v-2eb36915] {
    

background: rgb(139,195,235);
background: linear-gradient(329deg, rgba(139,195,235,1) 2%, rgba(20,92,148,0.9710084717480743) 34%); 
    box-shadow: 0 1px 1px 0 ;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 55px 50px;
    margin-top: 80px;
    bottom: 0;
    color: #fff;
}
.footer-navigation h3[data-v-2eb36915] {
    margin: 0;
    font: normal 36px Cookie, cursive;
    margin-bottom: 20px;
    color: #fff
}
.footer-navigation h3 a[data-v-2eb36915] {
    text-decoration: none;
    color: #fff
}
.footer-navigation h3 span[data-v-2eb36915] {
    color:  rgba(139, 195, 235, 1)
}
.footer-navigation p.links a[data-v-2eb36915] {
    color: #fff;
    text-decoration: none
}
.footer-navigation p.company-name[data-v-2eb36915] {
    color:  rgba(139, 195, 235, 1);
    font-size: 16px;
    font-weight: normal;
    margin-top: 20px
}
@media (max-width:767px) {
.footer-contacts[data-v-2eb36915] {
        margin: 30px 0
}
}
.footer-contacts p[data-v-2eb36915] {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle
}
.footer-contacts  a[data-v-2eb36915] {
    color:  rgba(139, 195, 235, 1);
    text-decoration: none
}
.fa.footer-contacts-icon[data-v-2eb36915] {
    background-color: white;
    color:rgba(20,92,148,0.9710084717480743);
    font-size: 18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    margin: 10px 15px 10px 0
}
span.new-line-span[data-v-2eb36915] {
    display: block;
    font-weight: normal;
    display: block;
    font-weight: normal;
    font-size: 16px;
    line-height: 2
}
.footer-about h4[data-v-2eb36915] {
    display: block;
    color: #fff;
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px
}
.footer-about p[data-v-2eb36915] {
    line-height: 20px;
    color: #bbc6cf;
    font-size: 16px;
    font-weight: normal;
    margin: 0
}
div.social-links[data-v-2eb36915] {
    margin-top: 20px;
    color: #fff
}
.social-links a[data-v-2eb36915] {
    display: inline-block;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color:white;
    border-radius: 30px;
    font-size: 30px;
    color: rgba(20,92,148,0.9710084717480743);
    text-align: center;
    line-height: 50px;
    margin-right: 10px;
    margin-bottom: 5px
}
.footer[data-v-2eb36915] {
    bottom: 0;
}
body[data-v-2eb36915] {
    background: #eee
}
body[data-v-17ed0f5e]{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 150px;
    margin-bottom: 150px;
}
.m[data-v-17ed0f5e]{
margin-top: 100px;
margin-bottom: 70px;
}
.burgundy[data-v-17ed0f5e] {
    color: rgba(20,92,148,0.9710084717480743);
    font-size: 50px;
    font-weight: 400;
}
.orange[data-v-17ed0f5e]{
    font-size: 45px;
    color: grey;
    font-weight: 400;
}
.card[data-v-17ed0f5e]{
    position: relative;
    width: 390px;
    height: 250px;
    margin: 20px;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    transition: all .2s linear;
    border-radius: 10px;
}
img[data-v-17ed0f5e]{
    width: 100%;
    transition: all .2s linear;
}
.card-content[data-v-17ed0f5e]{
    position: absolute;
    bottom: 3rem;
    right: 100%;
    width: 100%;
    text-align: center;
    text-decoration: none;
    transition: all .2s linear;
    text-decoration: none;
}
a[data-v-17ed0f5e], a[data-v-17ed0f5e]:hover, a[data-v-17ed0f5e]:visited, a[data-v-17ed0f5e]:active, a[data-v-17ed0f5e]:link{
    text-decoration: none;
    color: #FFFFFF;
}
.card-category[data-v-17ed0f5e]{
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 2px;
    padding: 10px;
    margin: 15px;
    color: #000;
    background-color: rgba(255,255,255,.7);
    border-radius: 10px;
}
i[data-v-17ed0f5e]{
    margin-left: 5px;
}
.card[data-v-17ed0f5e]:hover{
      box-shadow: 0 0px 28px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(0,0,0,0.22)
}
.card:hover .card-content[data-v-17ed0f5e]{
    right: 0;
}
.card:hover img[data-v-17ed0f5e]{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
  
  
  
  
  
  
**[data-v-17ed0f5e] {
  box-sizing: border-box;
}
#products.row[data-v-17ed0f5e] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#search[data-v-17ed0f5e] {
  outline: none;
  border: none;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.product-item[data-v-17ed0f5e] {
  height: 400px;
  width: 300px;
}
.card-img-top[data-v-17ed0f5e] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.burgundy[data-v-17ed0f5e] {
  color: rgba(20,92,148,0.9710084717480743);
  font-size: 50px;
  font-weight: 400;
  text-decoration: none;
}
.orange[data-v-17ed0f5e]{
  font-size: 45px;
  color: grey;
  font-weight: 400;
  text-decoration: none;
}
.card-img-top[data-v-17ed0f5e] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card[data-v-17ed0f5e] {
  max-width: 16rem;
}
.select[data-v-17ed0f5e]{
  color: grey;
}
.btn[data-v-17ed0f5e] {
  color: grey;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
button[data-v-17ed0f5e]{
  border-radius: 30px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}

/* .btn:hover {
 
  background: rgb(235,36,44);
  background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%); 
} */
.fa-search[data-v-17ed0f5e] {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 3px;
  padding-right: 20px;
}
.search-nav-item[data-v-17ed0f5e] {
  height: 60px;
  margin-top: 150px;
}
nav[data-v-17ed0f5e] {
  padding: 0px 100px;
}
.fa-user-o[data-v-17ed0f5e],
.fa-shopping-cart[data-v-17ed0f5e] {
  font-size: 20px;
  padding: 4px;
}
.form-group[data-v-17ed0f5e] {
  margin-bottom: 5px;
}
label[data-v-17ed0f5e] {
  padding-left: 10px;
}
.form-group[data-v-17ed0f5e]:last-child {
  margin-bottom: 0;
}
h6[data-v-17ed0f5e] {
  margin-bottom: 0px;
}
#sort[data-v-17ed0f5e] {
  outline: none;
  border: none;
}
.btn[data-v-17ed0f5e] {
  border-radius: 50px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn[data-v-17ed0f5e]:hover {
  color: grey;
  background-color: rgb(95, 89, 89);
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card-body[data-v-17ed0f5e] {
  padding: 8px;
}
.sign[data-v-17ed0f5e] {
  width: 25px;
  height: 25px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.discount[data-v-17ed0f5e] {
  border: 2px solid red;
  border-radius: 2px;
  width: 65px;
  position: absolute;
  top: 2%;
}
.rounded[data-v-17ed0f5e] {
  border-radius: 10px !important;
}
@media (min-width: 1200px) {
#search[data-v-17ed0f5e] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-17ed0f5e] {
    margin-left: 400px;
    width: 350px;
}
.fa-user-o[data-v-17ed0f5e] {
    margin-left: 300px;
}
.text[data-v-17ed0f5e] {
    display: none;
}
.fa-user-o[data-v-17ed0f5e],
  .fa-shopping-cart[data-v-17ed0f5e] {
    font-size: 20px;
    padding-left: 20px;
}
#sidebar[data-v-17ed0f5e] {
    width: 30%;
    padding: 20px;
    float: left;
}
#products[data-v-17ed0f5e] {
    padding: 10px;
    margin: 0;
}
.card[data-v-17ed0f5e] {

    border: none;
}
del[data-v-17ed0f5e] {
    padding-right: 2px;
}
.filter[data-v-17ed0f5e],
  #mobile-filter[data-v-17ed0f5e] {
    display: none;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
#search[data-v-17ed0f5e] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-17ed0f5e] {
    margin-left: 200px;
    width: 350px;
}
.fa-user-o[data-v-17ed0f5e] {
    margin-left: 160px;
}
.text[data-v-17ed0f5e] {
    display: none;
}
#products[data-v-17ed0f5e] {
    padding: 10px;
    margin: 0;
}
.card[data-v-17ed0f5e] {
    border: none;
}
.fa-plus[data-v-17ed0f5e],
  .fa-minus[data-v-17ed0f5e] {
    font-size: 12px;
}
.sign[data-v-17ed0f5e] {
    height: 25px;
}
.price[data-v-17ed0f5e] {
    width: 99px;
}
.filter[data-v-17ed0f5e],
  #mobile-filter[data-v-17ed0f5e] {
    display: none;
}
}
@media (min-width: 768px) and (max-width: 991px) {
#search[data-v-17ed0f5e] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-17ed0f5e] {
    margin-left: 60px;
    width: 350px;
}
.fa-user-o[data-v-17ed0f5e] {
    margin-left: 80px;
}
.text[data-v-17ed0f5e] {
    display: none;
}
#sidebar[data-v-17ed0f5e] {
    width: 35%;
    padding: 20px;
    float: left;
}
#products[data-v-17ed0f5e] {
    width: 65%;
    padding: 10px;
    margin: 0;
    float: right;
}
.card[data-v-17ed0f5e] {
    border: none;
}
.filter[data-v-17ed0f5e],
  #mobile-filter[data-v-17ed0f5e] {
    display: none;
}
}
@media (min-width: 576px) and (max-width: 767px) {
.text[data-v-17ed0f5e] {
    display: none;
}
.search-nav-item[data-v-17ed0f5e] {
    margin-left: 35px;
    width: 270px;
}
#search[data-v-17ed0f5e] {
    width: 240px;
    padding: 5px;
    padding-left: 20px;
}
.fa-search[data-v-17ed0f5e] {
    padding: 3px;
    font-size: 18px;
}
#sidebar[data-v-17ed0f5e] {
    width: 40%;
    padding: 20px;
    float: left;
}
#products[data-v-17ed0f5e] {
    padding: 10px;
    margin: 0;
}
.card[data-v-17ed0f5e] {
    border: none;
}
#off[data-v-17ed0f5e] {
    padding-left: 2px;
}
#sorting span[data-v-17ed0f5e],
  #res[data-v-17ed0f5e] {
    font-size: 14px;
}
.filter[data-v-17ed0f5e],
  #mobile-filter[data-v-17ed0f5e] {
    display: none;
}
}
@media (max-width: 575px) {
.search-nav-item[data-v-17ed0f5e] {
    margin: 0;
    width: 100%;
    margin-top: 10px;
}
#search[data-v-17ed0f5e] {
    width: 80%;
    padding-left: 10px;
    padding-right: 10px;
}
.fa-search[data-v-17ed0f5e] {
    width: 80%;
    padding: 10px;
    font-size: 18px;
}
#sidebar[data-v-17ed0f5e] {
    display: none;
}
.filter[data-v-17ed0f5e] {
    margin-left: 70%;
    margin-top: 2%;
}
#sorting[data-v-17ed0f5e],
  #res[data-v-17ed0f5e] {
    font-size: 12px;
    margin-top: 2%;
}
}

body[data-v-f70651f4] {
  font-family: Poppins;
}
.carousel-item[data-v-f70651f4] {
  height: 100vh;
  min-height: 300px;
}
.carousel-img[data-v-f70651f4] {
  height: 100%;
  object-fit: cover;
}
.carousel-caption[data-v-f70651f4] {
  bottom: 220px;
}
.carousel-caption h5[data-v-f70651f4] {
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 25px;
  font-weight: 900;
  text-shadow: black 1px 0 10px;
}
.carousel-caption p[data-v-f70651f4] {
  font-size: 18px;
  margin: auto;
  width: 60%;
  line-height: 1.9;
  text-shadow: black 1px 0 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.carousel-caption a[data-v-f70651f4] {
  text-transform: uppercase;
  background: rgba(20, 92, 148, 1);
  padding: 10px 30px;
  display: inline-block;
  color: #fff;
  margin-top: 15px;
  border-radius: 50px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
       text-shadow: black 1px 0 10px;
}
.navbar-toggler[data-v-f70651f4]:focus,
.navbar-toggler[data-v-f70651f4]:active,
.navbar-toggler-icon[data-v-f70651f4]:focus {
    outline: none;
    box-shadow: none;
}
.navbar-nav a[data-v-f70651f4]{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;
}
.navbar-light img[data-v-f70651f4]{
    color: #fff;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
}
.navbar-light .navbar-nav .active > .nav-link[data-v-f70651f4],
.navbar-light .navbar-nav .nav-link.active[data-v-f70651f4],
.navbar-light .navbar-nav .nav-link.show[data-v-f70651f4],
.navbar-light .navbar-nav .show > .nav-link[data-v-f70651f4]{
    color: #fff;
}
.navbar-light .navbar-nav .nav-link[data-v-f70651f4]{
    color: #fff;
}
.nav-link[data-v-f70651f4]{
    padding: .2rem 1rem;
    letter-spacing: 1px;
}
.nav-link[data-v-f70651f4]:hover{
  color: aqua;
}
.navbar-light .navbar-nav .nav-link[data-v-f70651f4]:focus, 
.navbar-light .navbar-nav .nav-link[data-v-f70651f4]:hover{
    color: #fff;
}
navbar-toggler[data-v-f70651f4]{
    padding: 1px 5px;
    font-size: 18px;
    line-height: .3;
    background: #fff;
}
w-100[data-v-f70651f4]{
    height: 100vh;
}
@media only screen and (max-width: 767px){
.navbar-nav.ml-auto[data-v-f70651f4]{
        background:rgba(165, 159, 159, 0.376);
        border-radius:4px;
}
.navbar-nav a[data-v-f70651f4]{
        font-size: 14px;
        font-weight: normal;

        padding: 8px;
}
}
.carousel-indicators li[data-v-f70651f4] {
    border: 2px solid rgba(255, 255, 255, 0.5);
    height: 17px;
    margin-left: 2.5px;
    margin-right: 2.5px;
    opacity: 0.5;
    width: 17px;
    border-radius: 40px;
}
.carousel-indicators li[data-v-f70651f4]::after {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.75);
    content: '';
    display: block;
    height: 1px;
    width: 100%;
}
.carousel-indicators .active[data-v-f70651f4] {
    opacity: 1;
}
.carousel-item img[data-v-f70651f4]{
  opacity: 0.9;
}
*[data-v-45f73ee5]{
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body[data-v-45f73ee5]{
  height: 100vh;
  background: rgb(235,36,44);
  background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%);
}
#error-page[data-v-45f73ee5]{
  position: absolute;
  top: 10%;
  left: 15%;
  right: 15%;
  bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
  margin-top: 50px;
}
#error-page .content[data-v-45f73ee5]{
  max-width: 600px;
  text-align: center;
}
.content h2.header[data-v-45f73ee5]{
  font-size: 15vw;
  line-height: 1em;
  position: relative;
}
.content h2.header[data-v-45f73ee5]:after{
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  background: -webkit-repeating-linear-gradient(-45deg, #71b7e6, #0a65a1, #c01313);
  background-size: 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(255,255,255,0.25);
  -webkit-animation: animate-data-v-45f73ee5 10s ease-in-out infinite;
          animation: animate-data-v-45f73ee5 10s ease-in-out infinite;
}
@-webkit-keyframes animate-data-v-45f73ee5 {
0%{
    background-position: 0 0;
}
25%{
    background-position: 100% 0;
}
50%{
    background-position: 100% 100%;
}
75%{
    background-position: 0% 100%;
}
100%{
    background-position: 0% 0%;
}
}
@keyframes animate-data-v-45f73ee5 {
0%{
    background-position: 0 0;
}
25%{
    background-position: 100% 0;
}
50%{
    background-position: 100% 100%;
}
75%{
    background-position: 0% 100%;
}
100%{
    background-position: 0% 0%;
}
}
.content h4[data-v-45f73ee5]{
  font-size: 1.5em;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000;
  font-size: 2em;
  max-width: 600px;
  position: relative;
}
.content h4[data-v-45f73ee5]:after{
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  text-shadow: 1px 1px 2px rgba(255,255,255,0.4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content p[data-v-45f73ee5]{
  font-size: 1.2em;
  color: #0d0d0d;
}
.content .btns[data-v-45f73ee5]{
  margin: 25px 0;
  display: inline-flex;
}
.content .btns a[data-v-45f73ee5]{
  display: inline-block;
  margin: 0 10px;
  text-decoration: none;
  border: 2px solid #69a6ce;
  color: #69a6ce;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.content .btns a[data-v-45f73ee5]:hover{
  background: #69a6ce;
  color: #fff;
}
*{
  font-family: "Poppins";
  font-weight:  regular 400;
}

.signin-background {
  background: rgb(235, 36, 44);
  background: linear-gradient(
    326deg,
    rgba(235, 36, 44, 1) 12%,
    rgba(20, 92, 148, 1) 60%,
    rgba(139, 195, 235, 1) 100%
  );
}

.signup-background {
  background: rgb(235, 36, 44);
  background: linear-gradient(
    326deg,
    rgba(235, 36, 44, 1) 12%,
    rgba(20, 92, 148, 1) 60%,
    rgba(139, 195, 235, 1) 100%
  );
}

/* .btn-default {
  background-color: pink;
  border-radius: 40px;
} */

.carousel-image img {
  height: calc(100vh - 88px) !important;
  object-fit: cover !important;
  
}

.website-body{
  overflow-x:hidden;
}

.logo-img {
  width: 250px;
}

.navbar{
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, .2);
}

.bbtn {
  border-radius: 18px;
  box-shadow: inset 0 3px 10px rgba(100, 100, 100, 0.1);
  border-radius: 20px;
}

h1 {
  padding: 60px;
  -webkit-text-decoration-color: white;
          text-decoration-color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 3.5rem;
}

.colorr {
  border-radius: 18px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow:  20px 20px 60px #bebebe,
               -20px -20px 60px #ffffff;

      padding: 50px;
}

.product-container {
  width: 100%;
}

@media(min-width:768px) and (max-width:991px){
  .product-container {
    width: 90%;
  }
}

@media (min-width:1200px) {
  .product-container {
    width: 75%;
  }
}

*[data-v-8f3b2970]{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html[data-v-8f3b2970]{
    font-size: 10px;
    font-family: 'Poppins';
}
body[data-v-8f3b2970]{
    background: rgb(235,36,44);
    background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%);
}
.center-content[data-v-8f3b2970]{
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 0 1.5rem;
}
.form-container[data-v-8f3b2970]{
    width: 100%;
    max-width: 50rem;
    background: rgb(235,36,44);
    background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%); 
    padding: 3.5rem;
    margin: auto;
    border-radius: .8rem;
    box-shadow:  .5rem .5rem 1.5rem rgb(42, 120, 180), -.5rem -.5rem 1.5rem rgb(42, 120, 180);
}
input[data-v-8f3b2970]{
    display: block;
    width: 100%;
    color: #ddd;
    font-size: 1rem;
    padding: 1.2rem;
    background-color: transparent;
    border: none;
    border-radius: 2rem;
    box-shadow:inset .5rem .5rem 1.5rem rgba(20, 92, 148, 1),
    inset -.5rem -.5rem 1.5rem rgb(42, 120, 180); 
    margin-bottom: 3.5rem;
    outline: none;
}
input[data-v-8f3b2970]:focus{
    box-shadow: .5rem .5rem 1.5rem rgb(42, 120, 180), -.5rem -.5rem 1.5rem rgba(20, 92, 148, 1);
}
input[data-v-8f3b2970]::-webkit-input-placeholder{
    color: rgb(253, 253, 253);
}
input[data-v-8f3b2970]:-ms-input-placeholder{
    color: rgb(253, 253, 253);
}
input[data-v-8f3b2970]::placeholder{
    color: rgb(253, 253, 253);
}
.input-group[data-v-8f3b2970]{
    display: flex;
}
.button[data-v-8f3b2970]{
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight:bold ;
    text-transform: uppercase;
    color: #ddd;
    background-color: transparent;
    border: none;
    border-radius: 2rem;
    letter-spacing: 2px;
    outline: none;
    padding: 1rem;
    box-shadow: .5rem .5rem 1.5rem rgba(20, 92, 148, 1),
    -.5rem -.5rem 1.5rem rgb(42, 120, 180);
    cursor: pointer;
}
.button[data-v-8f3b2970]:hover,
.button[data-v-8f3b2970]:focus{
    box-shadow:inset .5rem .5rem 1.5rem rgba(20, 92, 148, 1),
    inset -.5rem -.5rem 1.5rem rgb(42, 120, 180);
}
@media screen and (max-width: 600px){
.input-group[data-v-8f3b2970]{
       display: block;
}
input-group input[data-v-8f3b2970]:first-child{
       margin-right: 0;
}
}
/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
.nav[data-v-84fc96a2]{
 
  display: flex;
  padding: 10px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.nav-pills .nav-link.active[data-v-84fc96a2], .nav-pills .show > .nav-link[data-v-84fc96a2]{

  background-color:transparent;
  border-radius: 50px;
  padding: 10px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}


/* Rounded tabs */
@media (min-width: 576px) {
.rounded-nav[data-v-84fc96a2] {
    border-radius: 50px !important;
}
}
@media (min-width: 576px) {
.rounded-nav .nav-link[data-v-84fc96a2] {
    border-radius: 50px !important;
}
}


/*****************globals*************/
body[data-v-a0afba49] {
	font-family: "open sans";
	overflow-x: hidden;
}
img[data-v-a0afba49] {
	max-width: 100%;
}
a[data-v-a0afba49] {
	text-transform: uppercase;
	background: rgba(20, 92, 148, 1);
	padding: 10px 30px;
	display: inline-block;
	color: #fff;
	margin-top: 15px;
	border-radius: 50px;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
	text-shadow: black 1px 0 10px;
}
.preview[data-v-a0afba49] {
	display: flex;
	flex-direction: column;
}
@media screen and (max-width: 996px) {
.preview[data-v-a0afba49] {
	  margin-bottom: 20px;
}
}
.preview-pic[data-v-a0afba49] {
	flex-grow: 1;
}
.preview-thumbnail.nav-tabs[data-v-a0afba49] {
	border: none;
	margin-top: 15px;
}
.preview-thumbnail.nav-tabs li[data-v-a0afba49] {
	width: 18%;
	margin-right: 2.5%;
}
.preview-thumbnail.nav-tabs li img[data-v-a0afba49] {
	max-width: 100%;
	display: block;
}
.preview-thumbnail.nav-tabs li a[data-v-a0afba49] {
	padding: 0;
	margin: 0;
}
.preview-thumbnail.nav-tabs li[data-v-a0afba49]:last-of-type {
	margin-right: 0;
}
.tab-button[data-v-a0afba49] {
	cursor: pointer;
}
.tab-button img[data-v-a0afba49] {
	height: 75px;
	object-fit: cover;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.tab-content[data-v-a0afba49] {
	overflow: hidden;
}
.tab-content img[data-v-a0afba49] {
	height: 400px;
	width: 100%;
	object-fit: cover;
	object-position: top;
	-webkit-animation-name: opacity-data-v-a0afba49;
	animation-name: opacity-data-v-a0afba49;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
}
.card[data-v-a0afba49] {
	margin-top: 200px;
	margin-bottom: 100px;
	background: #eee;
	padding: 3em;
	border-radius: 18px;
	border: none;
	line-height: 1.5em;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
@media screen and (min-width: 997px) {
.wrapper[data-v-a0afba49] {
	  display: flex;
}
}
.details[data-v-a0afba49] {
	display: flex;
	flex-direction: column;
}
.colors[data-v-a0afba49] {
	flex-grow: 1;
}
.product-title[data-v-a0afba49],
  .price[data-v-a0afba49],
  .sizes[data-v-a0afba49],
  .colors[data-v-a0afba49] {
	text-transform: UPPERCASE;
	font-weight: bold;
}
.checked[data-v-a0afba49],
  .price span[data-v-a0afba49] {
	color: rgba(20, 92, 148, 1);
}
.product-status[data-v-a0afba49] {
	color: grey;
}
.product-title[data-v-a0afba49],
  .rating[data-v-a0afba49],
  .product-description[data-v-a0afba49],
  .price[data-v-a0afba49],
  .vote[data-v-a0afba49],
  .sizes[data-v-a0afba49] {
	margin-bottom: 15px;
}
.product-title[data-v-a0afba49] {
	margin-top: 0;
}
.size[data-v-a0afba49] {
	margin-right: 10px;
}
.size[data-v-a0afba49]:first-of-type {
	margin-left: 40px;
}
.color[data-v-a0afba49] {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	height: 2em;
	width: 2em;
	border-radius: 2px;
}
.color[data-v-a0afba49]:first-of-type {
	margin-left: 20px;
}
.not-available[data-v-a0afba49] {
	text-align: center;
	line-height: 2em;
}
.not-available[data-v-a0afba49]:before {
	font-family: fontawesome;
	content: "\f00d";
	color: #fff;
}
.orange[data-v-a0afba49] {
	background: #ff9f1a;
}
.green[data-v-a0afba49] {
	background: #85ad00;
}
.blue[data-v-a0afba49] {
	background: #0076ad;
}
.tooltip-inner[data-v-a0afba49] {
	padding: 1.3em;
}
@-webkit-keyframes opacity-data-v-a0afba49 {
0% {
	  opacity: 0;
	  -webkit-transform: scale(3);
	  transform: scale(3);
}
100% {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
}
}
@keyframes opacity-data-v-a0afba49 {
0% {
	  opacity: 0;
	  -webkit-transform: scale(3);
	  transform: scale(3);
}
100% {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
}
}
  
.backi{
    background: rgb(235,36,44);
    background: linear-gradient(326deg, rgba(235,36,44,1) 12%, rgba(20,92,148,1) 60%, rgba(139,195,235,1) 100%);
    
    }

.modal-dialog{
    border-radius: 18px;
}
    
    .font{
        font-family: sans-serif;
        text-align: center;
    }
    
    
    
     h1{
         color: black;
         text-align: center;
     }
    
    .preview {
        width: 250px;
        height: 200px;
    }

    .preview1 {
        width: 500px;
        height: 200px;
    }
/*****************globals*************/
body[data-v-b5db40aa] {
	font-family: "open sans";
	overflow-x: hidden;
}
img[data-v-b5db40aa] {
	max-width: 100%;
}
a[data-v-b5db40aa] {
	text-transform: uppercase;
	background: rgba(20, 92, 148, 1);
	padding: 10px 30px;
	display: inline-block;
	color: #fff;
	margin-top: 15px;
	border-radius: 50px;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
	text-shadow: black 1px 0 10px;
}
.preview[data-v-b5db40aa] {
	display: flex;
	flex-direction: column;
}
@media screen and (max-width: 996px) {
.preview[data-v-b5db40aa] {
	  margin-bottom: 20px;
}
}
.preview-pic[data-v-b5db40aa] {
	flex-grow: 1;
}
.preview-thumbnail.nav-tabs[data-v-b5db40aa] {
	border: none;
	margin-top: 15px;
}
.preview-thumbnail.nav-tabs li[data-v-b5db40aa] {
	width: 18%;
	margin-right: 2.5%;
}
.preview-thumbnail.nav-tabs li img[data-v-b5db40aa] {
	max-width: 100%;
	display: block;
}
.preview-thumbnail.nav-tabs li a[data-v-b5db40aa] {
	padding: 0;
	margin: 0;
}
.preview-thumbnail.nav-tabs li[data-v-b5db40aa]:last-of-type {
	margin-right: 0;
}
.tab-button[data-v-b5db40aa] {
	cursor: pointer;
}
.tab-button img[data-v-b5db40aa] {
	height: 75px;
	object-fit: cover;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.tab-content[data-v-b5db40aa] {
	overflow: hidden;
}
.tab-content img[data-v-b5db40aa] {
	height: 400px;
	width: 100%;
	object-fit: cover;
	object-position: top;
	-webkit-animation-name: opacity-data-v-b5db40aa;
	animation-name: opacity-data-v-b5db40aa;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
}
.card[data-v-b5db40aa] {
	margin-top: 200px;
	margin-bottom: 100px;
	background: #eee;
	padding: 3em;
	border-radius: 18px;
	border: none;
	line-height: 1.5em;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
@media screen and (min-width: 997px) {
.wrapper[data-v-b5db40aa] {
	  display: flex;
}
}
.details[data-v-b5db40aa] {
	display: flex;
	flex-direction: column;
}
.colors[data-v-b5db40aa] {
	flex-grow: 1;
}
.product-title[data-v-b5db40aa],
  .price[data-v-b5db40aa],
  .sizes[data-v-b5db40aa],
  .colors[data-v-b5db40aa] {
	text-transform: UPPERCASE;
	font-weight: bold;
}
.checked[data-v-b5db40aa],
  .price span[data-v-b5db40aa] {
	color: rgba(20, 92, 148, 1);
}
.product-status[data-v-b5db40aa] {
	color: grey;
}
.product-title[data-v-b5db40aa],
  .rating[data-v-b5db40aa],
  .product-description[data-v-b5db40aa],
  .price[data-v-b5db40aa],
  .vote[data-v-b5db40aa],
  .sizes[data-v-b5db40aa] {
	margin-bottom: 15px;
}
.product-title[data-v-b5db40aa] {
	margin-top: 0;
}
.size[data-v-b5db40aa] {
	margin-right: 10px;
}
.size[data-v-b5db40aa]:first-of-type {
	margin-left: 40px;
}
.color[data-v-b5db40aa] {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	height: 2em;
	width: 2em;
	border-radius: 2px;
}
.color[data-v-b5db40aa]:first-of-type {
	margin-left: 20px;
}
.not-available[data-v-b5db40aa] {
	text-align: center;
	line-height: 2em;
}
.not-available[data-v-b5db40aa]:before {
	font-family: fontawesome;
	content: "\f00d";
	color: #fff;
}
.orange[data-v-b5db40aa] {
	background: #ff9f1a;
}
.green[data-v-b5db40aa] {
	background: #85ad00;
}
.blue[data-v-b5db40aa] {
	background: #0076ad;
}
.tooltip-inner[data-v-b5db40aa] {
	padding: 1.3em;
}
@-webkit-keyframes opacity-data-v-b5db40aa {
0% {
	  opacity: 0;
	  -webkit-transform: scale(3);
	  transform: scale(3);
}
100% {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
}
}
@keyframes opacity-data-v-b5db40aa {
0% {
	  opacity: 0;
	  -webkit-transform: scale(3);
	  transform: scale(3);
}
100% {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
}
}
  
/*****************globals*************/
body[data-v-2fe05fe0] {
	font-family: "open sans";
	overflow-x: hidden;
}
img[data-v-2fe05fe0] {
	max-width: 100%;
}
a[data-v-2fe05fe0] {
	text-transform: uppercase;
	background: rgba(20, 92, 148, 1);
	padding: 10px 30px;
	display: inline-block;
	color: #fff;
	margin-top: 15px;
	border-radius: 50px;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
	text-shadow: black 1px 0 10px;
}
.preview[data-v-2fe05fe0] {
	display: flex;
	flex-direction: column;
}
@media screen and (max-width: 996px) {
.preview[data-v-2fe05fe0] {
	  margin-bottom: 20px;
}
}
.preview-pic[data-v-2fe05fe0] {
	flex-grow: 1;
}
.preview-thumbnail.nav-tabs[data-v-2fe05fe0] {
	border: none;
	margin-top: 15px;
}
.preview-thumbnail.nav-tabs li[data-v-2fe05fe0] {
	width: 18%;
	margin-right: 2.5%;
}
.preview-thumbnail.nav-tabs li img[data-v-2fe05fe0] {
	max-width: 100%;
	display: block;
}
.preview-thumbnail.nav-tabs li a[data-v-2fe05fe0] {
	padding: 0;
	margin: 0;
}
.preview-thumbnail.nav-tabs li[data-v-2fe05fe0]:last-of-type {
	margin-right: 0;
}
.tab-button[data-v-2fe05fe0] {
	cursor: pointer;
}
.tab-button img[data-v-2fe05fe0] {
	height: 75px;
	object-fit: cover;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.tab-content[data-v-2fe05fe0] {
	overflow: hidden;
}
.tab-content img[data-v-2fe05fe0] {
	height: 400px;
	width: 100%;
	object-fit: cover;
	object-position: top;
	-webkit-animation-name: opacity-data-v-2fe05fe0;
	animation-name: opacity-data-v-2fe05fe0;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
}
.card[data-v-2fe05fe0] {
	margin-top: 200px;
	margin-bottom: 100px;
	background: #eee;
	padding: 3em;
	border-radius: 18px;
	border: none;
	line-height: 1.5em;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
@media screen and (min-width: 997px) {
.wrapper[data-v-2fe05fe0] {
	  display: flex;
}
}
.details[data-v-2fe05fe0] {
	display: flex;
	flex-direction: column;
}
.colors[data-v-2fe05fe0] {
	flex-grow: 1;
}
.product-title[data-v-2fe05fe0],
  .price[data-v-2fe05fe0],
  .sizes[data-v-2fe05fe0],
  .colors[data-v-2fe05fe0] {
	text-transform: UPPERCASE;
	font-weight: bold;
}
.checked[data-v-2fe05fe0],
  .price span[data-v-2fe05fe0] {
	color: rgba(20, 92, 148, 1);
}
.product-status[data-v-2fe05fe0] {
	color: grey;
}
.product-title[data-v-2fe05fe0],
  .rating[data-v-2fe05fe0],
  .product-description[data-v-2fe05fe0],
  .price[data-v-2fe05fe0],
  .vote[data-v-2fe05fe0],
  .sizes[data-v-2fe05fe0] {
	margin-bottom: 15px;
}
.product-title[data-v-2fe05fe0] {
	margin-top: 0;
}
.size[data-v-2fe05fe0] {
	margin-right: 10px;
}
.size[data-v-2fe05fe0]:first-of-type {
	margin-left: 40px;
}
.color[data-v-2fe05fe0] {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	height: 2em;
	width: 2em;
	border-radius: 2px;
}
.color[data-v-2fe05fe0]:first-of-type {
	margin-left: 20px;
}
.not-available[data-v-2fe05fe0] {
	text-align: center;
	line-height: 2em;
}
.not-available[data-v-2fe05fe0]:before {
	font-family: fontawesome;
	content: "\f00d";
	color: #fff;
}
.orange[data-v-2fe05fe0] {
	background: #ff9f1a;
}
.green[data-v-2fe05fe0] {
	background: #85ad00;
}
.blue[data-v-2fe05fe0] {
	background: #0076ad;
}
.tooltip-inner[data-v-2fe05fe0] {
	padding: 1.3em;
}
@-webkit-keyframes opacity-data-v-2fe05fe0 {
0% {
	  opacity: 0;
	  -webkit-transform: scale(3);
	  transform: scale(3);
}
100% {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
}
}
@keyframes opacity-data-v-2fe05fe0 {
0% {
	  opacity: 0;
	  -webkit-transform: scale(3);
	  transform: scale(3);
}
100% {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
}
}
  
body[data-v-42f8fe6f]{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 150px;
    margin-bottom: 150px;
}
.m[data-v-42f8fe6f]{
margin-top: 100px;
margin-bottom: 70px;
}
.burgundy[data-v-42f8fe6f] {
    color: rgba(20,92,148,0.9710084717480743);
    font-size: 50px;
    font-weight: 400;
}
.orange[data-v-42f8fe6f]{
    font-size: 45px;
    color: grey;
    font-weight: 400;
}
.card[data-v-42f8fe6f]{
    position: relative;
    width: 390px;
    height: 250px;
    margin: 20px;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    transition: all .2s linear;
    border-radius: 10px;
}
img[data-v-42f8fe6f]{
    width: 100%;
    transition: all .2s linear;
}
.card-content[data-v-42f8fe6f]{
    position: absolute;
    bottom: 3rem;
    right: 100%;
    width: 100%;
    text-align: center;
    text-decoration: none;
    transition: all .2s linear;
    text-decoration: none;
}
a[data-v-42f8fe6f], a[data-v-42f8fe6f]:hover, a[data-v-42f8fe6f]:visited, a[data-v-42f8fe6f]:active, a[data-v-42f8fe6f]:link{
    text-decoration: none;
    color: #FFFFFF;
}
.card-category[data-v-42f8fe6f]{
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 2px;
    padding: 10px;
    margin: 15px;
    color: #000;
    background-color: rgba(255,255,255,.7);
    border-radius: 10px;
}
i[data-v-42f8fe6f]{
    margin-left: 5px;
}
.card[data-v-42f8fe6f]:hover{
      box-shadow: 0 0px 28px rgba(0, 0, 0, 0.25), 0 4px 10px rgba(0,0,0,0.22)
}
.card:hover .card-content[data-v-42f8fe6f]{
    right: 0;
}
.card:hover img[data-v-42f8fe6f]{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
  
  
  
  
  
  
*[data-v-edcde27b]{
    margin: 0;
    padding: 0;
    font-family: "Open Sans",sans-serif;
    box-sizing: border-box;
}
.burgundy[data-v-edcde27b] {
    color: rgba(20,92,148,0.9710084717480743);
    font-size: 50px;
    font-weight: 400;
}
.orange[data-v-edcde27b]{
    font-size: 45px;
    color: grey;
    font-weight: 400;
}
body[data-v-edcde27b]{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
}
.about-title[data-v-edcde27b] {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}
.center-margin[data-v-edcde27b] {
    margin-top: 6px;
}
.about-section[data-v-edcde27b]{
    background: url(/static/media/about.30d7d26a.jpg) no-repeat left;
    background-size: 55%;
    background-color: #fdfdfd;
    overflow: hidden;
    padding: 100px 0;
}
.inner-container[data-v-edcde27b]{
    width: 55%;
    float: right;
    background-color:rgb(255, 255, 255);
    padding: 150px;
    border-radius: 15px;
    box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, .2);
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.inner-container h1[data-v-edcde27b]{
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 900;
}
.text[data-v-edcde27b]{
    font-size: 13px;
    color: #545454;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 40px;
}
.skills[data-v-edcde27b]{
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 13px;
}
@media screen and (max-width:1200px){
.inner-container[data-v-edcde27b]{
        padding: 80px;
}
}
@media screen and (max-width:1000px){
.about-section[data-v-edcde27b]{
        background-size: 100%;
        padding: 100px 40px;
}
.inner-container[data-v-edcde27b]{
        width: 100%;
}
}
@media screen and (max-width:600px){
.about-title[data-v-edcde27b] {
        margin-top: 5rem;
}
.about-section[data-v-edcde27b]{
        padding: 0;
}
.inner-container[data-v-edcde27b]{
        padding: 60px;
}
}
body[data-v-134ddeea] {
	background: #eee;
}
.m[data-v-134ddeea] {
	margin-top: 190px;
}
.burgundy[data-v-134ddeea] {
	color: rgba(20,92,148,0.9710084717480743);
	font-size: 50px;
	font-weight: 400;
}
.orange[data-v-134ddeea]{
	font-size: 45px;
	color: grey;
	font-weight: 400;
}
.date__box[data-v-134ddeea] {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ccc;
	border: 4px solid;
	font-weight: bold;
	padding: 5px 10px;
	border-radius: 15px;
}
.date__box .date__day[data-v-134ddeea] {
	font-size: 22px;
}
.blog-card[data-v-134ddeea] {
	padding: 30px;
	position: relative;
	border-radius: 15px;
	margin-top: 90px;
}
.blog-card .date__box[data-v-134ddeea] {
	opacity: 0;
	-webkit-transform: scale(0.5);
	        transform: scale(0.5);
	transition: 300ms ease-in-out;
	border-radius: 15px;
}
.blog-card .blog-card__background[data-v-134ddeea], .blog-card .card__background--layer[data-v-134ddeea] {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 15px;
}
.blog-card .blog-card__background[data-v-134ddeea] {
	padding: 15px;
	background: rgb(255, 255, 255);
	border-radius: 15px;
}
.blog-card .card__background--wrapper[data-v-134ddeea] {
	height: 100%;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
	        clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
	position: relative;
	overflow: hidden;
}
.blog-card .card__background--main[data-v-134ddeea] {
	height: 100%;
	position: relative;
	transition: 300ms ease-in-out;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 15px;
}
.blog-card .card__background--layer[data-v-134ddeea] {
	z-index: 0;
	opacity: 0;
	background: rgba(51, 51, 51, 0.9);
	transition: 300ms ease-in-out;
}
.blog-card .blog-card__head[data-v-134ddeea] {
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.blog-card .blog-card__info[data-v-134ddeea] {
	z-index: 10;
	background: rgb(255, 255, 255);
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
	padding: 20px 15px;
	border-radius: 15px;
}
.blog-card .blog-card__info h5[data-v-134ddeea] {
	transition: 300ms ease-in-out;
}
.blog-card:hover .date__box[data-v-134ddeea] {
	opacity: 1;
	-webkit-transform: scale(1);
	        transform: scale(1);
}
.blog-card:hover .card__background--main[data-v-134ddeea] {
	-webkit-transform: scale(1.2) rotate(5deg);
	        transform: scale(1.2) rotate(5deg);
}
.blog-card:hover .card__background--layer[data-v-134ddeea] {
	opacity: 1;
}
.blog-card:hover .blog-card__info h5[data-v-134ddeea] {
	color:  rgba(20, 92, 148, 1);
}
a.icon-link[data-v-134ddeea] {
	color: #363738;
	transition: 200ms ease-in-out;
}
a.icon-link i[data-v-134ddeea] {
	color:  rgba(20, 92, 148, 1);
}
a.icon-link[data-v-134ddeea]:hover {
	color: rgba(20, 92, 148, 1);
	text-decoration: none;
}
.btn[data-v-134ddeea] {
	background: white;
	color: #363738;
	font-weight: bold;
	outline: none;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
	border-radius: 0;
	height: 50px;
	line-height: 50px;
	display: inline-block;
	padding: 0;
	border: none;
	border-radius: 15px;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn[data-v-134ddeea]:focus {
	box-shadow: none;
}
.btn[data-v-134ddeea]:hover {
	background:  rgba(20, 92, 148, 1);
	color: #fff;
	border-radius: 15px;
}
.btn.btn--with-icon[data-v-134ddeea] {
	padding-right: 20px;
}
.btn.btn--with-icon i[data-v-134ddeea] {
	padding: 0px 30px 0px 15px;
	margin-right: 10px;
	height: 50px;
	line-height: 50px;
	vertical-align: bottom;
	color: white;
	background: rgba(20, 92, 148, 1);
	-webkit-clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
	        clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
}
.btn.btn--only-icon[data-v-134ddeea] {
	width: 50px;
}

body[data-v-e18b6c39] {
	background: #eee;
}
.m[data-v-e18b6c39] {
	margin-top: 190px;
}
.burgundy[data-v-e18b6c39] {
	color: rgba(20,92,148,0.9710084717480743);
	font-size: 50px;
	font-weight: 400;
}
.orange[data-v-e18b6c39]{
	font-size: 45px;
	color: grey;
	font-weight: 400;
}
.date__box[data-v-e18b6c39] {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ccc;
	border: 4px solid;
	font-weight: bold;
	padding: 5px 10px;
	border-radius: 15px;
}
.date__box .date__day[data-v-e18b6c39] {
	font-size: 22px;
}
.blog-card[data-v-e18b6c39] {
	padding: 30px;
	position: relative;
	border-radius: 15px;
	margin-top: 90px;
}
.blog-card .date__box[data-v-e18b6c39] {
	opacity: 0;
	-webkit-transform: scale(0.5);
	        transform: scale(0.5);
	transition: 300ms ease-in-out;
	border-radius: 15px;
}
.blog-card .blog-card__background[data-v-e18b6c39], .blog-card .card__background--layer[data-v-e18b6c39] {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 15px;
}
.blog-card .blog-card__background[data-v-e18b6c39] {
	padding: 15px;
	background: rgb(255, 255, 255);
	border-radius: 15px;
}
.blog-card .card__background--wrapper[data-v-e18b6c39] {
	height: 100%;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
	        clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
	position: relative;
	overflow: hidden;
}
.blog-card .card__background--main[data-v-e18b6c39] {
	height: 100%;
	position: relative;
	transition: 300ms ease-in-out;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 15px;
}
.blog-card .card__background--layer[data-v-e18b6c39] {
	z-index: 0;
	opacity: 0;
	background: rgba(51, 51, 51, 0.9);
	transition: 300ms ease-in-out;
}
.blog-card .blog-card__head[data-v-e18b6c39] {
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.blog-card .blog-card__info[data-v-e18b6c39] {
	z-index: 10;
	background: rgb(255, 255, 255);
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
	padding: 20px 15px;
	border-radius: 15px;
}
.blog-card .blog-card__info h5[data-v-e18b6c39] {
	transition: 300ms ease-in-out;
}
.blog-card:hover .date__box[data-v-e18b6c39] {
	opacity: 1;
	-webkit-transform: scale(1);
	        transform: scale(1);
}
.blog-card:hover .card__background--main[data-v-e18b6c39] {
	-webkit-transform: scale(1.2) rotate(5deg);
	        transform: scale(1.2) rotate(5deg);
}
.blog-card:hover .card__background--layer[data-v-e18b6c39] {
	opacity: 1;
}
.blog-card:hover .blog-card__info h5[data-v-e18b6c39] {
	color:  rgba(20, 92, 148, 1);
}
a.icon-link[data-v-e18b6c39] {
	color: #363738;
	transition: 200ms ease-in-out;
}
a.icon-link i[data-v-e18b6c39] {
	color:  rgba(20, 92, 148, 1);
}
a.icon-link[data-v-e18b6c39]:hover {
	color: rgba(20, 92, 148, 1);
	text-decoration: none;
}
.btn[data-v-e18b6c39] {
	background: white;
	color: #363738;
	font-weight: bold;
	outline: none;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
	border-radius: 0;
	height: 50px;
	line-height: 50px;
	display: inline-block;
	padding: 0;
	border: none;
	border-radius: 15px;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn[data-v-e18b6c39]:focus {
	box-shadow: none;
}
.btn[data-v-e18b6c39]:hover {
	background:  rgba(20, 92, 148, 1);
	color: #fff;
	border-radius: 15px;
}
.btn.btn--with-icon[data-v-e18b6c39] {
	padding-right: 20px;
}
.btn.btn--with-icon i[data-v-e18b6c39] {
	padding: 0px 30px 0px 15px;
	margin-right: 10px;
	height: 50px;
	line-height: 50px;
	vertical-align: bottom;
	color: white;
	background: rgba(20, 92, 148, 1);
	-webkit-clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
	        clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
}
.btn.btn--only-icon[data-v-e18b6c39] {
	width: 50px;
}

body[data-v-e613a426] {
  font-family: Poppins;
}
.carousel-item[data-v-e613a426] {
  height: 100vh;
  min-height: 300px;
}
.carousel-img[data-v-e613a426] {
  height: 100%;
  object-fit: cover;
}
.carousel-caption[data-v-e613a426] {
  bottom: 220px;
}
.carousel-caption h5[data-v-e613a426] {
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 25px;
  font-weight: 900;
  text-shadow: black 1px 0 10px;
}
.carousel-caption p[data-v-e613a426] {
  font-size: 18px;
  margin: auto;
  width: 60%;
  line-height: 1.9;
  text-shadow: black 1px 0 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.carousel-caption a[data-v-e613a426] {
  text-transform: uppercase;
  background: rgba(20, 92, 148, 1);
  padding: 10px 30px;
  display: inline-block;
  color: #fff;
  margin-top: 15px;
  border-radius: 50px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
       text-shadow: black 1px 0 10px;
}
.navbar-toggler[data-v-e613a426]:focus,
.navbar-toggler[data-v-e613a426]:active,
.navbar-toggler-icon[data-v-e613a426]:focus {
    outline: none;
    box-shadow: none;
}
.navbar-nav a[data-v-e613a426]{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;
}
.navbar-light img[data-v-e613a426]{
    color: #fff;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
}
.navbar-light .navbar-nav .active > .nav-link[data-v-e613a426],
.navbar-light .navbar-nav .nav-link.active[data-v-e613a426],
.navbar-light .navbar-nav .nav-link.show[data-v-e613a426],
.navbar-light .navbar-nav .show > .nav-link[data-v-e613a426]{
    color: #fff;
}
.navbar-light .navbar-nav .nav-link[data-v-e613a426]{
    color: #fff;
}
.nav-link[data-v-e613a426]{
    padding: .2rem 1rem;
    letter-spacing: 1px;
}
.nav-link[data-v-e613a426]:hover{
  color: aqua;
}
.navbar-light .navbar-nav .nav-link[data-v-e613a426]:focus, 
.navbar-light .navbar-nav .nav-link[data-v-e613a426]:hover{
    color: #fff;
}
navbar-toggler[data-v-e613a426]{
    padding: 1px 5px;
    font-size: 18px;
    line-height: .3;
    background: #fff;
}
w-100[data-v-e613a426]{
    height: 100vh;
}
@media only screen and (max-width: 767px){
.navbar-nav.ml-auto[data-v-e613a426]{
        background:rgba(165, 159, 159, 0.376);
        border-radius:4px;
}
.navbar-nav a[data-v-e613a426]{
        font-size: 14px;
        font-weight: normal;

        padding: 8px;
}
}
.carousel-indicators li[data-v-e613a426] {
    border: 2px solid rgba(255, 255, 255, 0.5);
    height: 17px;
    margin-left: 2.5px;
    margin-right: 2.5px;
    opacity: 0.5;
    width: 17px;
    border-radius: 40px;
}
.carousel-indicators li[data-v-e613a426]::after {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.75);
    content: '';
    display: block;
    height: 1px;
    width: 100%;
}
.carousel-indicators .active[data-v-e613a426] {
    opacity: 1;
}
.carousel-item img[data-v-e613a426]{
  opacity: 0.9;
}
/*****************globals*************/
body[data-v-b245af9c] {
    font-family: 'open sans';
    overflow-x: hidden;
}
.player-wrapper[data-v-b245af9c] {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}
.react-player[data-v-b245af9c] {
  position: absolute;
  top: 0;
  left: 0;
}
img[data-v-b245af9c] {
    max-width: 100%;
}
a[data-v-b245af9c] {
  text-transform: uppercase;
  background: rgba(20, 92, 148, 1);
  padding: 10px 30px;
  display: inline-block;
  color: #fff;
  margin-top: 15px;
  border-radius: 50px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
       text-shadow: black 1px 0 10px;
}
.preview[data-v-b245af9c] {
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 996px) {
.preview[data-v-b245af9c] {
        margin-bottom: 20px;
}
}
.preview-pic[data-v-b245af9c] {
    flex-grow: 1;
}
.preview-thumbnail.nav-tabs[data-v-b245af9c] {
    border: none;
    margin-top: 15px;
}
.preview-thumbnail.nav-tabs li[data-v-b245af9c] {
      width: 18%;
      margin-right: 2.5%;
}
.preview-thumbnail.nav-tabs li img[data-v-b245af9c] {
        max-width: 100%;
        display: block;
}
.preview-thumbnail.nav-tabs li a[data-v-b245af9c] {
        padding: 0;
        margin: 0;
}
.preview-thumbnail.nav-tabs li[data-v-b245af9c]:last-of-type {
        margin-right: 0;
}
.tab-content[data-v-b245af9c] {
    overflow: hidden;
}
.tab-content img[data-v-b245af9c] {
      width: 100%;
      -webkit-animation-name: opacity-data-v-b245af9c;
              animation-name: opacity-data-v-b245af9c;
      -webkit-animation-duration: .3s;
              animation-duration: .3s;
}
.card[data-v-b245af9c] {
    margin-top: 200px;
    margin-bottom: 100px;
    background: #eee;
    padding: 3em;
    border-radius: 18px;
    border: none;
    line-height: 1.5em;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
@media screen and (min-width: 997px) {
.wrapper[data-v-b245af9c] {
      display: flex;
}
}
.details[data-v-b245af9c] {
    display: flex;
    flex-direction: column;
}
.colors[data-v-b245af9c] {
    flex-grow: 1;
}
.product-title[data-v-b245af9c], .price[data-v-b245af9c], .sizes[data-v-b245af9c], .colors[data-v-b245af9c] {
    text-transform: UPPERCASE;
    font-weight: bold;
}
.checked[data-v-b245af9c], .price span[data-v-b245af9c] {
    color:  rgba(20, 92, 148, 1);
}
.product-status[data-v-b245af9c]{
   color:grey;
}
.product-title[data-v-b245af9c], .rating[data-v-b245af9c], .product-description[data-v-b245af9c], .price[data-v-b245af9c], .vote[data-v-b245af9c], .sizes[data-v-b245af9c] {
    margin-bottom: 15px;
}
.product-title[data-v-b245af9c] {
    margin-top: 0;
}
.size[data-v-b245af9c] {
    margin-right: 10px;
}
.size[data-v-b245af9c]:first-of-type {
      margin-left: 40px;
}
.color[data-v-b245af9c] {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    height: 2em;
    width: 2em;
    border-radius: 2px;
}
.color[data-v-b245af9c]:first-of-type {
      margin-left: 20px;
}
.not-available[data-v-b245af9c] {
    text-align: center;
    line-height: 2em;
}
.not-available[data-v-b245af9c]:before {
      font-family: fontawesome;
      content: "\f00d";
      color: #fff;
}
.orange[data-v-b245af9c] {
    background: #ff9f1a;
}
.green[data-v-b245af9c] {
    background: #85ad00;
}
.blue[data-v-b245af9c] {
    background: #0076ad;
}
.tooltip-inner[data-v-b245af9c] {
    padding: 1.3em;
}
@-webkit-keyframes opacity-data-v-b245af9c {
0% {
      opacity: 0;
      -webkit-transform: scale(3);
              transform: scale(3);
}
100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
}
}
@keyframes opacity-data-v-b245af9c {
0% {
      opacity: 0;
      -webkit-transform: scale(3);
              transform: scale(3);
}
100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
}
}
