.backi{
    background: rgb(235,36,44);
    background: linear-gradient(326deg, rgba(235,36,44,1) 12%, rgba(20,92,148,1) 60%, rgba(139,195,235,1) 100%);
    
    }

.modal-dialog{
    border-radius: 18px;
}
    
    .font{
        font-family: sans-serif;
        text-align: center;
    }
    
    
    
     h1{
         color: black;
         text-align: center;
     }
    
    .preview {
        width: 250px;
        height: 200px;
    }

    .preview1 {
        width: 500px;
        height: 200px;
    }