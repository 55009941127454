body[data-v-e18b6c39] {
	background: #eee;
}
.m[data-v-e18b6c39] {
	margin-top: 190px;
}
.burgundy[data-v-e18b6c39] {
	color: rgba(20,92,148,0.9710084717480743);
	font-size: 50px;
	font-weight: 400;
}
.orange[data-v-e18b6c39]{
	font-size: 45px;
	color: grey;
	font-weight: 400;
}
.date__box[data-v-e18b6c39] {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ccc;
	border: 4px solid;
	font-weight: bold;
	padding: 5px 10px;
	border-radius: 15px;
}
.date__box .date__day[data-v-e18b6c39] {
	font-size: 22px;
}
.blog-card[data-v-e18b6c39] {
	padding: 30px;
	position: relative;
	border-radius: 15px;
	margin-top: 90px;
}
.blog-card .date__box[data-v-e18b6c39] {
	opacity: 0;
	-webkit-transform: scale(0.5);
	        transform: scale(0.5);
	transition: 300ms ease-in-out;
	border-radius: 15px;
}
.blog-card .blog-card__background[data-v-e18b6c39], .blog-card .card__background--layer[data-v-e18b6c39] {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 15px;
}
.blog-card .blog-card__background[data-v-e18b6c39] {
	padding: 15px;
	background: rgb(255, 255, 255);
	border-radius: 15px;
}
.blog-card .card__background--wrapper[data-v-e18b6c39] {
	height: 100%;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
	        clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
	position: relative;
	overflow: hidden;
}
.blog-card .card__background--main[data-v-e18b6c39] {
	height: 100%;
	position: relative;
	transition: 300ms ease-in-out;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 15px;
}
.blog-card .card__background--layer[data-v-e18b6c39] {
	z-index: 0;
	opacity: 0;
	background: rgba(51, 51, 51, 0.9);
	transition: 300ms ease-in-out;
}
.blog-card .blog-card__head[data-v-e18b6c39] {
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.blog-card .blog-card__info[data-v-e18b6c39] {
	z-index: 10;
	background: rgb(255, 255, 255);
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
	padding: 20px 15px;
	border-radius: 15px;
}
.blog-card .blog-card__info h5[data-v-e18b6c39] {
	transition: 300ms ease-in-out;
}
.blog-card:hover .date__box[data-v-e18b6c39] {
	opacity: 1;
	-webkit-transform: scale(1);
	        transform: scale(1);
}
.blog-card:hover .card__background--main[data-v-e18b6c39] {
	-webkit-transform: scale(1.2) rotate(5deg);
	        transform: scale(1.2) rotate(5deg);
}
.blog-card:hover .card__background--layer[data-v-e18b6c39] {
	opacity: 1;
}
.blog-card:hover .blog-card__info h5[data-v-e18b6c39] {
	color:  rgba(20, 92, 148, 1);
}
a.icon-link[data-v-e18b6c39] {
	color: #363738;
	transition: 200ms ease-in-out;
}
a.icon-link i[data-v-e18b6c39] {
	color:  rgba(20, 92, 148, 1);
}
a.icon-link[data-v-e18b6c39]:hover {
	color: rgba(20, 92, 148, 1);
	text-decoration: none;
}
.btn[data-v-e18b6c39] {
	background: white;
	color: #363738;
	font-weight: bold;
	outline: none;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
	border-radius: 0;
	height: 50px;
	line-height: 50px;
	display: inline-block;
	padding: 0;
	border: none;
	border-radius: 15px;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn[data-v-e18b6c39]:focus {
	box-shadow: none;
}
.btn[data-v-e18b6c39]:hover {
	background:  rgba(20, 92, 148, 1);
	color: #fff;
	border-radius: 15px;
}
.btn.btn--with-icon[data-v-e18b6c39] {
	padding-right: 20px;
}
.btn.btn--with-icon i[data-v-e18b6c39] {
	padding: 0px 30px 0px 15px;
	margin-right: 10px;
	height: 50px;
	line-height: 50px;
	vertical-align: bottom;
	color: white;
	background: rgba(20, 92, 148, 1);
	-webkit-clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
	        clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
}
.btn.btn--only-icon[data-v-e18b6c39] {
	width: 50px;
}
