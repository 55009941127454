@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
*[data-v-8f3b2970]{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html[data-v-8f3b2970]{
    font-size: 10px;
    font-family: 'Poppins';
}
body[data-v-8f3b2970]{
    background: rgb(235,36,44);
    background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%);
}
.center-content[data-v-8f3b2970]{
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 0 1.5rem;
}
.form-container[data-v-8f3b2970]{
    width: 100%;
    max-width: 50rem;
    background: rgb(235,36,44);
    background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%); 
    padding: 3.5rem;
    margin: auto;
    border-radius: .8rem;
    box-shadow:  .5rem .5rem 1.5rem rgb(42, 120, 180), -.5rem -.5rem 1.5rem rgb(42, 120, 180);
}
input[data-v-8f3b2970]{
    display: block;
    width: 100%;
    color: #ddd;
    font-size: 1rem;
    padding: 1.2rem;
    background-color: transparent;
    border: none;
    border-radius: 2rem;
    box-shadow:inset .5rem .5rem 1.5rem rgba(20, 92, 148, 1),
    inset -.5rem -.5rem 1.5rem rgb(42, 120, 180); 
    margin-bottom: 3.5rem;
    outline: none;
}
input[data-v-8f3b2970]:focus{
    box-shadow: .5rem .5rem 1.5rem rgb(42, 120, 180), -.5rem -.5rem 1.5rem rgba(20, 92, 148, 1);
}
input[data-v-8f3b2970]::-webkit-input-placeholder{
    color: rgb(253, 253, 253);
}
input[data-v-8f3b2970]:-ms-input-placeholder{
    color: rgb(253, 253, 253);
}
input[data-v-8f3b2970]::placeholder{
    color: rgb(253, 253, 253);
}
.input-group[data-v-8f3b2970]{
    display: flex;
}
.button[data-v-8f3b2970]{
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight:bold ;
    text-transform: uppercase;
    color: #ddd;
    background-color: transparent;
    border: none;
    border-radius: 2rem;
    letter-spacing: 2px;
    outline: none;
    padding: 1rem;
    box-shadow: .5rem .5rem 1.5rem rgba(20, 92, 148, 1),
    -.5rem -.5rem 1.5rem rgb(42, 120, 180);
    cursor: pointer;
}
.button[data-v-8f3b2970]:hover,
.button[data-v-8f3b2970]:focus{
    box-shadow:inset .5rem .5rem 1.5rem rgba(20, 92, 148, 1),
    inset -.5rem -.5rem 1.5rem rgb(42, 120, 180);
}
@media screen and (max-width: 600px){
.input-group[data-v-8f3b2970]{
       display: block;
}
input-group input[data-v-8f3b2970]:first-child{
       margin-right: 0;
}
}