@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
*[data-v-2fb4ecbf] {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body[data-v-2fb4ecbf] {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.serv[data-v-2fb4ecbf]{
  margin-top: 300px;
  padding: 100px;
}
.container[data-v-2fb4ecbf] {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  flex-wrap: wrap;
  padding: 40px 0;
}
.container .card[data-v-2fb4ecbf] {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  margin: 30px;
}
.burgundy[data-v-2fb4ecbf] {
  color: rgba(20,92,148,0.9710084717480743);
  font-size: 50px;
  font-weight: 400;
}
.orange[data-v-2fb4ecbf]{
  font-size: 45px;
  color: grey;
  font-weight: 400;
}
.container .card .box[data-v-2fb4ecbf] {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #e6e6e6;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.container .card:hover .box[data-v-2fb4ecbf] {
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  box-shadow: 0 10px 40px rgba(0 0, 0, 0.2);
  background: rgb(235,36,44);
  background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%);
}
.container .card .box .content[data-v-2fb4ecbf] {
  padding: 20px;
  white-space: pre-wrap;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.container .card .box .content h2[data-v-2fb4ecbf] {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8em;
  color: rgba(0, 0, 0, 0.04);
  transition: 0.6s;
  pointer-events: none;
}
.container .card:hover .box .content h2[data-v-2fb4ecbf] {
  color: rgba(0, 0, 0, 0.1);
}
.container .card .box .content h3[data-v-2fb4ecbf] {
  font-size: 1.8em;
  color: #777;
  z-index: 1;
  transition: 0.5s;
}
.container .card .box .content p[data-v-2fb4ecbf] {
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  color: #777;
  z-index: 1;
  transition: 0.5s;
  white-space: pre-wrap;
}
.container .card:hover .box .content h3[data-v-2fb4ecbf],
.container .card:hover .box .content p[data-v-2fb4ecbf] {
  color: #fff;
}
.container .card .box .content a[data-v-2fb4ecbf] {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: rgba(20, 92, 148, 1);
  margin-top: 15px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.container .card:hover .box .content a[data-v-2fb4ecbf] {
  background: rgba(139, 195, 235, 1);
}
#burgundy[data-v-2fb4ecbf] {
  color: red;
  font-size: 30px;
}
#orange[data-v-2fb4ecbf] {
  color: grey;
}
h3[data-v-2fb4ecbf]{
  margin-bottom: 10px;
}