*[data-v-edcde27b]{
    margin: 0;
    padding: 0;
    font-family: "Open Sans",sans-serif;
    box-sizing: border-box;
}
.burgundy[data-v-edcde27b] {
    color: rgba(20,92,148,0.9710084717480743);
    font-size: 50px;
    font-weight: 400;
}
.orange[data-v-edcde27b]{
    font-size: 45px;
    color: grey;
    font-weight: 400;
}
body[data-v-edcde27b]{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
}
.about-title[data-v-edcde27b] {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}
.center-margin[data-v-edcde27b] {
    margin-top: 6px;
}
.about-section[data-v-edcde27b]{
    background: url(../images/about.jpg) no-repeat left ;
    background-size: 55%;
    background-color: #fdfdfd;
    overflow: hidden;
    padding: 100px 0;
}
.inner-container[data-v-edcde27b]{
    width: 55%;
    float: right;
    background-color:rgb(255, 255, 255);
    padding: 150px;
    border-radius: 15px;
    box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, .2);
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.inner-container h1[data-v-edcde27b]{
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 900;
}
.text[data-v-edcde27b]{
    font-size: 13px;
    color: #545454;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 40px;
}
.skills[data-v-edcde27b]{
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 13px;
}
@media screen and (max-width:1200px){
.inner-container[data-v-edcde27b]{
        padding: 80px;
}
}
@media screen and (max-width:1000px){
.about-section[data-v-edcde27b]{
        background-size: 100%;
        padding: 100px 40px;
}
.inner-container[data-v-edcde27b]{
        width: 100%;
}
}
@media screen and (max-width:600px){
.about-title[data-v-edcde27b] {
        margin-top: 5rem;
}
.about-section[data-v-edcde27b]{
        padding: 0;
}
.inner-container[data-v-edcde27b]{
        padding: 60px;
}
}