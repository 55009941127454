/*****************globals*************/
body[data-v-b245af9c] {
    font-family: 'open sans';
    overflow-x: hidden;
}
.player-wrapper[data-v-b245af9c] {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}
.react-player[data-v-b245af9c] {
  position: absolute;
  top: 0;
  left: 0;
}
img[data-v-b245af9c] {
    max-width: 100%;
}
a[data-v-b245af9c] {
  text-transform: uppercase;
  background: rgba(20, 92, 148, 1);
  padding: 10px 30px;
  display: inline-block;
  color: #fff;
  margin-top: 15px;
  border-radius: 50px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
       text-shadow: black 1px 0 10px;
}
.preview[data-v-b245af9c] {
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 996px) {
.preview[data-v-b245af9c] {
        margin-bottom: 20px;
}
}
.preview-pic[data-v-b245af9c] {
    flex-grow: 1;
}
.preview-thumbnail.nav-tabs[data-v-b245af9c] {
    border: none;
    margin-top: 15px;
}
.preview-thumbnail.nav-tabs li[data-v-b245af9c] {
      width: 18%;
      margin-right: 2.5%;
}
.preview-thumbnail.nav-tabs li img[data-v-b245af9c] {
        max-width: 100%;
        display: block;
}
.preview-thumbnail.nav-tabs li a[data-v-b245af9c] {
        padding: 0;
        margin: 0;
}
.preview-thumbnail.nav-tabs li[data-v-b245af9c]:last-of-type {
        margin-right: 0;
}
.tab-content[data-v-b245af9c] {
    overflow: hidden;
}
.tab-content img[data-v-b245af9c] {
      width: 100%;
      -webkit-animation-name: opacity-data-v-b245af9c;
              animation-name: opacity-data-v-b245af9c;
      -webkit-animation-duration: .3s;
              animation-duration: .3s;
}
.card[data-v-b245af9c] {
    margin-top: 200px;
    margin-bottom: 100px;
    background: #eee;
    padding: 3em;
    border-radius: 18px;
    border: none;
    line-height: 1.5em;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
@media screen and (min-width: 997px) {
.wrapper[data-v-b245af9c] {
      display: flex;
}
}
.details[data-v-b245af9c] {
    display: flex;
    flex-direction: column;
}
.colors[data-v-b245af9c] {
    flex-grow: 1;
}
.product-title[data-v-b245af9c], .price[data-v-b245af9c], .sizes[data-v-b245af9c], .colors[data-v-b245af9c] {
    text-transform: UPPERCASE;
    font-weight: bold;
}
.checked[data-v-b245af9c], .price span[data-v-b245af9c] {
    color:  rgba(20, 92, 148, 1);
}
.product-status[data-v-b245af9c]{
   color:grey;
}
.product-title[data-v-b245af9c], .rating[data-v-b245af9c], .product-description[data-v-b245af9c], .price[data-v-b245af9c], .vote[data-v-b245af9c], .sizes[data-v-b245af9c] {
    margin-bottom: 15px;
}
.product-title[data-v-b245af9c] {
    margin-top: 0;
}
.size[data-v-b245af9c] {
    margin-right: 10px;
}
.size[data-v-b245af9c]:first-of-type {
      margin-left: 40px;
}
.color[data-v-b245af9c] {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    height: 2em;
    width: 2em;
    border-radius: 2px;
}
.color[data-v-b245af9c]:first-of-type {
      margin-left: 20px;
}
.not-available[data-v-b245af9c] {
    text-align: center;
    line-height: 2em;
}
.not-available[data-v-b245af9c]:before {
      font-family: fontawesome;
      content: "\f00d";
      color: #fff;
}
.orange[data-v-b245af9c] {
    background: #ff9f1a;
}
.green[data-v-b245af9c] {
    background: #85ad00;
}
.blue[data-v-b245af9c] {
    background: #0076ad;
}
.tooltip-inner[data-v-b245af9c] {
    padding: 1.3em;
}
@-webkit-keyframes opacity-data-v-b245af9c {
0% {
      opacity: 0;
      -webkit-transform: scale(3);
              transform: scale(3);
}
100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
}
}
@keyframes opacity-data-v-b245af9c {
0% {
      opacity: 0;
      -webkit-transform: scale(3);
              transform: scale(3);
}
100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
}
}