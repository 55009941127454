/*****************globals*************/
body[data-v-2fe05fe0] {
	font-family: "open sans";
	overflow-x: hidden;
}
img[data-v-2fe05fe0] {
	max-width: 100%;
}
a[data-v-2fe05fe0] {
	text-transform: uppercase;
	background: rgba(20, 92, 148, 1);
	padding: 10px 30px;
	display: inline-block;
	color: #fff;
	margin-top: 15px;
	border-radius: 50px;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
	text-shadow: black 1px 0 10px;
}
.preview[data-v-2fe05fe0] {
	display: flex;
	flex-direction: column;
}
@media screen and (max-width: 996px) {
.preview[data-v-2fe05fe0] {
	  margin-bottom: 20px;
}
}
.preview-pic[data-v-2fe05fe0] {
	flex-grow: 1;
}
.preview-thumbnail.nav-tabs[data-v-2fe05fe0] {
	border: none;
	margin-top: 15px;
}
.preview-thumbnail.nav-tabs li[data-v-2fe05fe0] {
	width: 18%;
	margin-right: 2.5%;
}
.preview-thumbnail.nav-tabs li img[data-v-2fe05fe0] {
	max-width: 100%;
	display: block;
}
.preview-thumbnail.nav-tabs li a[data-v-2fe05fe0] {
	padding: 0;
	margin: 0;
}
.preview-thumbnail.nav-tabs li[data-v-2fe05fe0]:last-of-type {
	margin-right: 0;
}
.tab-button[data-v-2fe05fe0] {
	cursor: pointer;
}
.tab-button img[data-v-2fe05fe0] {
	height: 75px;
	object-fit: cover;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.tab-content[data-v-2fe05fe0] {
	overflow: hidden;
}
.tab-content img[data-v-2fe05fe0] {
	height: 400px;
	width: 100%;
	object-fit: cover;
	object-position: top;
	-webkit-animation-name: opacity-data-v-2fe05fe0;
	animation-name: opacity-data-v-2fe05fe0;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
}
.card[data-v-2fe05fe0] {
	margin-top: 200px;
	margin-bottom: 100px;
	background: #eee;
	padding: 3em;
	border-radius: 18px;
	border: none;
	line-height: 1.5em;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
	  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
	  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
@media screen and (min-width: 997px) {
.wrapper[data-v-2fe05fe0] {
	  display: flex;
}
}
.details[data-v-2fe05fe0] {
	display: flex;
	flex-direction: column;
}
.colors[data-v-2fe05fe0] {
	flex-grow: 1;
}
.product-title[data-v-2fe05fe0],
  .price[data-v-2fe05fe0],
  .sizes[data-v-2fe05fe0],
  .colors[data-v-2fe05fe0] {
	text-transform: UPPERCASE;
	font-weight: bold;
}
.checked[data-v-2fe05fe0],
  .price span[data-v-2fe05fe0] {
	color: rgba(20, 92, 148, 1);
}
.product-status[data-v-2fe05fe0] {
	color: grey;
}
.product-title[data-v-2fe05fe0],
  .rating[data-v-2fe05fe0],
  .product-description[data-v-2fe05fe0],
  .price[data-v-2fe05fe0],
  .vote[data-v-2fe05fe0],
  .sizes[data-v-2fe05fe0] {
	margin-bottom: 15px;
}
.product-title[data-v-2fe05fe0] {
	margin-top: 0;
}
.size[data-v-2fe05fe0] {
	margin-right: 10px;
}
.size[data-v-2fe05fe0]:first-of-type {
	margin-left: 40px;
}
.color[data-v-2fe05fe0] {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	height: 2em;
	width: 2em;
	border-radius: 2px;
}
.color[data-v-2fe05fe0]:first-of-type {
	margin-left: 20px;
}
.not-available[data-v-2fe05fe0] {
	text-align: center;
	line-height: 2em;
}
.not-available[data-v-2fe05fe0]:before {
	font-family: fontawesome;
	content: "\f00d";
	color: #fff;
}
.orange[data-v-2fe05fe0] {
	background: #ff9f1a;
}
.green[data-v-2fe05fe0] {
	background: #85ad00;
}
.blue[data-v-2fe05fe0] {
	background: #0076ad;
}
.tooltip-inner[data-v-2fe05fe0] {
	padding: 1.3em;
}
@-webkit-keyframes opacity-data-v-2fe05fe0 {
0% {
	  opacity: 0;
	  -webkit-transform: scale(3);
	  transform: scale(3);
}
100% {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
}
}
@keyframes opacity-data-v-2fe05fe0 {
0% {
	  opacity: 0;
	  -webkit-transform: scale(3);
	  transform: scale(3);
}
100% {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
}
}
  