body[data-v-e613a426] {
  font-family: Poppins;
}
.carousel-item[data-v-e613a426] {
  height: 100vh;
  min-height: 300px;
}
.carousel-img[data-v-e613a426] {
  height: 100%;
  object-fit: cover;
}
.carousel-caption[data-v-e613a426] {
  bottom: 220px;
}
.carousel-caption h5[data-v-e613a426] {
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 25px;
  font-weight: 900;
  text-shadow: black 1px 0 10px;
}
.carousel-caption p[data-v-e613a426] {
  font-size: 18px;
  margin: auto;
  width: 60%;
  line-height: 1.9;
  text-shadow: black 1px 0 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.carousel-caption a[data-v-e613a426] {
  text-transform: uppercase;
  background: rgba(20, 92, 148, 1);
  padding: 10px 30px;
  display: inline-block;
  color: #fff;
  margin-top: 15px;
  border-radius: 50px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
       text-shadow: black 1px 0 10px;
}
.navbar-toggler[data-v-e613a426]:focus,
.navbar-toggler[data-v-e613a426]:active,
.navbar-toggler-icon[data-v-e613a426]:focus {
    outline: none;
    box-shadow: none;
}
.navbar-nav a[data-v-e613a426]{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;
}
.navbar-light img[data-v-e613a426]{
    color: #fff;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
}
.navbar-light .navbar-nav .active > .nav-link[data-v-e613a426],
.navbar-light .navbar-nav .nav-link.active[data-v-e613a426],
.navbar-light .navbar-nav .nav-link.show[data-v-e613a426],
.navbar-light .navbar-nav .show > .nav-link[data-v-e613a426]{
    color: #fff;
}
.navbar-light .navbar-nav .nav-link[data-v-e613a426]{
    color: #fff;
}
.nav-link[data-v-e613a426]{
    padding: .2rem 1rem;
    letter-spacing: 1px;
}
.nav-link[data-v-e613a426]:hover{
  color: aqua;
}
.navbar-light .navbar-nav .nav-link[data-v-e613a426]:focus, 
.navbar-light .navbar-nav .nav-link[data-v-e613a426]:hover{
    color: #fff;
}
navbar-toggler[data-v-e613a426]{
    padding: 1px 5px;
    font-size: 18px;
    line-height: .3;
    background: #fff;
}
w-100[data-v-e613a426]{
    height: 100vh;
}
@media only screen and (max-width: 767px){
.navbar-nav.ml-auto[data-v-e613a426]{
        background:rgba(165, 159, 159, 0.376);
        border-radius:4px;
}
.navbar-nav a[data-v-e613a426]{
        font-size: 14px;
        font-weight: normal;

        padding: 8px;
}
}
.carousel-indicators li[data-v-e613a426] {
    border: 2px solid rgba(255, 255, 255, 0.5);
    height: 17px;
    margin-left: 2.5px;
    margin-right: 2.5px;
    opacity: 0.5;
    width: 17px;
    border-radius: 40px;
}
.carousel-indicators li[data-v-e613a426]::after {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.75);
    content: '';
    display: block;
    height: 1px;
    width: 100%;
}
.carousel-indicators .active[data-v-e613a426] {
    opacity: 1;
}
.carousel-item img[data-v-e613a426]{
  opacity: 0.9;
}