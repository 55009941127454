**[data-v-17ed0f5e] {
  box-sizing: border-box;
}
#products.row[data-v-17ed0f5e] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#search[data-v-17ed0f5e] {
  outline: none;
  border: none;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.product-item[data-v-17ed0f5e] {
  height: 400px;
  width: 300px;
}
.card-img-top[data-v-17ed0f5e] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.burgundy[data-v-17ed0f5e] {
  color: rgba(20,92,148,0.9710084717480743);
  font-size: 50px;
  font-weight: 400;
  text-decoration: none;
}
.orange[data-v-17ed0f5e]{
  font-size: 45px;
  color: grey;
  font-weight: 400;
  text-decoration: none;
}
.card-img-top[data-v-17ed0f5e] {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card[data-v-17ed0f5e] {
  max-width: 16rem;
}
.select[data-v-17ed0f5e]{
  color: grey;
}
.btn[data-v-17ed0f5e] {
  color: grey;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
       inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
       -5px -5px 5px rgba(255, 255, 255, 0.5);
}
button[data-v-17ed0f5e]{
  border-radius: 30px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}

/* .btn:hover {
 
  background: rgb(235,36,44);
  background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%); 
} */
.fa-search[data-v-17ed0f5e] {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 3px;
  padding-right: 20px;
}
.search-nav-item[data-v-17ed0f5e] {
  height: 60px;
  margin-top: 150px;
}
nav[data-v-17ed0f5e] {
  padding: 0px 100px;
}
.fa-user-o[data-v-17ed0f5e],
.fa-shopping-cart[data-v-17ed0f5e] {
  font-size: 20px;
  padding: 4px;
}
.form-group[data-v-17ed0f5e] {
  margin-bottom: 5px;
}
label[data-v-17ed0f5e] {
  padding-left: 10px;
}
.form-group[data-v-17ed0f5e]:last-child {
  margin-bottom: 0;
}
h6[data-v-17ed0f5e] {
  margin-bottom: 0px;
}
#sort[data-v-17ed0f5e] {
  outline: none;
  border: none;
}
.btn[data-v-17ed0f5e] {
  border-radius: 50px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn[data-v-17ed0f5e]:hover {
  color: grey;
  background-color: rgb(95, 89, 89);
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.card-body[data-v-17ed0f5e] {
  padding: 8px;
}
.sign[data-v-17ed0f5e] {
  width: 25px;
  height: 25px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.discount[data-v-17ed0f5e] {
  border: 2px solid red;
  border-radius: 2px;
  width: 65px;
  position: absolute;
  top: 2%;
}
.rounded[data-v-17ed0f5e] {
  border-radius: 10px !important;
}
@media (min-width: 1200px) {
#search[data-v-17ed0f5e] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-17ed0f5e] {
    margin-left: 400px;
    width: 350px;
}
.fa-user-o[data-v-17ed0f5e] {
    margin-left: 300px;
}
.text[data-v-17ed0f5e] {
    display: none;
}
.fa-user-o[data-v-17ed0f5e],
  .fa-shopping-cart[data-v-17ed0f5e] {
    font-size: 20px;
    padding-left: 20px;
}
#sidebar[data-v-17ed0f5e] {
    width: 30%;
    padding: 20px;
    float: left;
}
#products[data-v-17ed0f5e] {
    padding: 10px;
    margin: 0;
}
.card[data-v-17ed0f5e] {

    border: none;
}
del[data-v-17ed0f5e] {
    padding-right: 2px;
}
.filter[data-v-17ed0f5e],
  #mobile-filter[data-v-17ed0f5e] {
    display: none;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
#search[data-v-17ed0f5e] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-17ed0f5e] {
    margin-left: 200px;
    width: 350px;
}
.fa-user-o[data-v-17ed0f5e] {
    margin-left: 160px;
}
.text[data-v-17ed0f5e] {
    display: none;
}
#products[data-v-17ed0f5e] {
    padding: 10px;
    margin: 0;
}
.card[data-v-17ed0f5e] {
    border: none;
}
.fa-plus[data-v-17ed0f5e],
  .fa-minus[data-v-17ed0f5e] {
    font-size: 12px;
}
.sign[data-v-17ed0f5e] {
    height: 25px;
}
.price[data-v-17ed0f5e] {
    width: 99px;
}
.filter[data-v-17ed0f5e],
  #mobile-filter[data-v-17ed0f5e] {
    display: none;
}
}
@media (min-width: 768px) and (max-width: 991px) {
#search[data-v-17ed0f5e] {
    width: 300px;
    padding: 5px;
    padding-left: 20px;
}
.search-nav-item[data-v-17ed0f5e] {
    margin-left: 60px;
    width: 350px;
}
.fa-user-o[data-v-17ed0f5e] {
    margin-left: 80px;
}
.text[data-v-17ed0f5e] {
    display: none;
}
#sidebar[data-v-17ed0f5e] {
    width: 35%;
    padding: 20px;
    float: left;
}
#products[data-v-17ed0f5e] {
    width: 65%;
    padding: 10px;
    margin: 0;
    float: right;
}
.card[data-v-17ed0f5e] {
    border: none;
}
.filter[data-v-17ed0f5e],
  #mobile-filter[data-v-17ed0f5e] {
    display: none;
}
}
@media (min-width: 576px) and (max-width: 767px) {
.text[data-v-17ed0f5e] {
    display: none;
}
.search-nav-item[data-v-17ed0f5e] {
    margin-left: 35px;
    width: 270px;
}
#search[data-v-17ed0f5e] {
    width: 240px;
    padding: 5px;
    padding-left: 20px;
}
.fa-search[data-v-17ed0f5e] {
    padding: 3px;
    font-size: 18px;
}
#sidebar[data-v-17ed0f5e] {
    width: 40%;
    padding: 20px;
    float: left;
}
#products[data-v-17ed0f5e] {
    padding: 10px;
    margin: 0;
}
.card[data-v-17ed0f5e] {
    border: none;
}
#off[data-v-17ed0f5e] {
    padding-left: 2px;
}
#sorting span[data-v-17ed0f5e],
  #res[data-v-17ed0f5e] {
    font-size: 14px;
}
.filter[data-v-17ed0f5e],
  #mobile-filter[data-v-17ed0f5e] {
    display: none;
}
}
@media (max-width: 575px) {
.search-nav-item[data-v-17ed0f5e] {
    margin: 0;
    width: 100%;
    margin-top: 10px;
}
#search[data-v-17ed0f5e] {
    width: 80%;
    padding-left: 10px;
    padding-right: 10px;
}
.fa-search[data-v-17ed0f5e] {
    width: 80%;
    padding: 10px;
    font-size: 18px;
}
#sidebar[data-v-17ed0f5e] {
    display: none;
}
.filter[data-v-17ed0f5e] {
    margin-left: 70%;
    margin-top: 2%;
}
#sorting[data-v-17ed0f5e],
  #res[data-v-17ed0f5e] {
    font-size: 12px;
    margin-top: 2%;
}
}
