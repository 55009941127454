@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
body[data-v-9d754e9f] {
  background-color: #eeeeee;
  font-family: "Open Sans", serif;
  font-size: 14px;
}
#row-container.row[data-v-9d754e9f] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#burgundy[data-v-9d754e9f] {
  color: red;
  font-size: 30px;
}
#orange[data-v-9d754e9f],
select[data-v-9d754e9f],
.btn[data-v-9d754e9f] {
  color: grey;
}
.mt-100[data-v-9d754e9f] {
  margin-top: 150px;
}
.filter-group[data-v-9d754e9f] {
  border-bottom: 1px solid #e4e4e4;
}
.title[data-v-9d754e9f] {
  color: grey;
  font-weight: 500;
}
.card[data-v-9d754e9f] {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 4px solid rgba(104, 100, 100, 0.1);
  border-radius: 18px;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.category-link[data-v-9d754e9f] {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
}
.card-header[data-v-9d754e9f] {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 5px;
}
input[type="checkbox"][data-v-9d754e9f], input[type="radio"][data-v-9d754e9f] {
  box-sizing: border-box;
  padding: 0;
  border-radius: 30px;
}
.filter-group .card-header[data-v-9d754e9f] {
  border-bottom: 0;
}
.icon-control[data-v-9d754e9f] {
  margin-top: 6px;
  float: right;
  font-size: 80%;
  color: grey;
}
.list-menu[data-v-9d754e9f] {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.list-menu a[data-v-9d754e9f] {
  color: #343a40;
}
a[data-v-9d754e9f] {
  text-decoration: none !important;
  background-color: transparent;
}
.checkbox-btn[data-v-9d754e9f] {
  position: relative;
}
.checkbox-btn input[data-v-9d754e9f] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-btn input:checked ~ .btn[data-v-9d754e9f] {
  border-color: #3167eb;
  background-color: #3167eb;
  color: #fff;
}
.btn-light[data-v-9d754e9f] {
  display: inline-block;
  font-weight: 600;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #eee;
  border: 1px solid #eee;
  padding: 0.45rem 0.85rem;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 18px;
  outline: none;
}
.btn-light[data-v-9d754e9f]:hover {
  background-color: #fff;
  border-color: #989898;
  outline: none;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.btn-medium[data-v-9d754e9f] {
  font-size: 15px;
  padding: 10px 22px;
  display: inline-block;
  margin-right: 50px;
  letter-spacing: 2px;
  border: 1px  rgba(20, 92, 148, 1);
  width: 100%;
  outline: none;
  border-radius: 15px;
}
.custom-range[data-v-9d754e9f]::-webkit-slider-thumb {
  background: rgba(20, 92, 148, 1);
}
.custom-range[data-v-9d754e9f]::-moz-range-thumb {
  background: rgba(20, 92, 148, 1);
}
.custom-range[data-v-9d754e9f]::-ms-thumb {
  background: rgba(20, 92, 148, 1);
}
.highlight-button[data-v-9d754e9f]:hover {
  background-color: rgba(20, 92, 148, 1);
  color: #fff;
  outline: none;
}
.custom-control[data-v-9d754e9f] {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
