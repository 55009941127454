*{
  font-family: "Poppins";
  font-weight:  regular 400;
}

.signin-background {
  background: rgb(235, 36, 44);
  background: linear-gradient(
    326deg,
    rgba(235, 36, 44, 1) 12%,
    rgba(20, 92, 148, 1) 60%,
    rgba(139, 195, 235, 1) 100%
  );
}

.signup-background {
  background: rgb(235, 36, 44);
  background: linear-gradient(
    326deg,
    rgba(235, 36, 44, 1) 12%,
    rgba(20, 92, 148, 1) 60%,
    rgba(139, 195, 235, 1) 100%
  );
}

/* .btn-default {
  background-color: pink;
  border-radius: 40px;
} */

.carousel-image img {
  height: calc(100vh - 88px) !important;
  object-fit: cover !important;
  
}

.website-body{
  overflow-x:hidden;
}

.logo-img {
  width: 250px;
}

.navbar{
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, .2);
}

.bbtn {
  border-radius: 18px;
  box-shadow: inset 0 3px 10px rgba(100, 100, 100, 0.1);
  border-radius: 20px;
}

h1 {
  padding: 60px;
  -webkit-text-decoration-color: white;
          text-decoration-color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 3.5rem;
}

.colorr {
  border-radius: 18px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow:  20px 20px 60px #bebebe,
               -20px -20px 60px #ffffff;

      padding: 50px;
}

.product-container {
  width: 100%;
}

@media(min-width:768px) and (max-width:991px){
  .product-container {
    width: 90%;
  }
}

@media (min-width:1200px) {
  .product-container {
    width: 75%;
  }
}
