footer[data-v-2eb36915] {
    

background: rgb(139,195,235);
background: linear-gradient(329deg, rgba(139,195,235,1) 2%, rgba(20,92,148,0.9710084717480743) 34%); 
    box-shadow: 0 1px 1px 0 ;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 55px 50px;
    margin-top: 80px;
    bottom: 0;
    color: #fff;
}
.footer-navigation h3[data-v-2eb36915] {
    margin: 0;
    font: normal 36px Cookie, cursive;
    margin-bottom: 20px;
    color: #fff
}
.footer-navigation h3 a[data-v-2eb36915] {
    text-decoration: none;
    color: #fff
}
.footer-navigation h3 span[data-v-2eb36915] {
    color:  rgba(139, 195, 235, 1)
}
.footer-navigation p.links a[data-v-2eb36915] {
    color: #fff;
    text-decoration: none
}
.footer-navigation p.company-name[data-v-2eb36915] {
    color:  rgba(139, 195, 235, 1);
    font-size: 16px;
    font-weight: normal;
    margin-top: 20px
}
@media (max-width:767px) {
.footer-contacts[data-v-2eb36915] {
        margin: 30px 0
}
}
.footer-contacts p[data-v-2eb36915] {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle
}
.footer-contacts  a[data-v-2eb36915] {
    color:  rgba(139, 195, 235, 1);
    text-decoration: none
}
.fa.footer-contacts-icon[data-v-2eb36915] {
    background-color: white;
    color:rgba(20,92,148,0.9710084717480743);
    font-size: 18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    margin: 10px 15px 10px 0
}
span.new-line-span[data-v-2eb36915] {
    display: block;
    font-weight: normal;
    display: block;
    font-weight: normal;
    font-size: 16px;
    line-height: 2
}
.footer-about h4[data-v-2eb36915] {
    display: block;
    color: #fff;
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px
}
.footer-about p[data-v-2eb36915] {
    line-height: 20px;
    color: #bbc6cf;
    font-size: 16px;
    font-weight: normal;
    margin: 0
}
div.social-links[data-v-2eb36915] {
    margin-top: 20px;
    color: #fff
}
.social-links a[data-v-2eb36915] {
    display: inline-block;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color:white;
    border-radius: 30px;
    font-size: 30px;
    color: rgba(20,92,148,0.9710084717480743);
    text-align: center;
    line-height: 50px;
    margin-right: 10px;
    margin-bottom: 5px
}
.footer[data-v-2eb36915] {
    bottom: 0;
}
body[data-v-2eb36915] {
    background: #eee
}