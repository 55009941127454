@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
*[data-v-45f73ee5]{
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body[data-v-45f73ee5]{
  height: 100vh;
  background: rgb(235,36,44);
  background: linear-gradient(329deg, rgba(235,36,44,0.7889356426164216) 2%, rgba(20,92,148,0.9710084717480743) 49%, rgba(139,195,235,1) 95%);
}
#error-page[data-v-45f73ee5]{
  position: absolute;
  top: 10%;
  left: 15%;
  right: 15%;
  bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
  margin-top: 50px;
}
#error-page .content[data-v-45f73ee5]{
  max-width: 600px;
  text-align: center;
}
.content h2.header[data-v-45f73ee5]{
  font-size: 15vw;
  line-height: 1em;
  position: relative;
}
.content h2.header[data-v-45f73ee5]:after{
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  background: -webkit-repeating-linear-gradient(-45deg, #71b7e6, #0a65a1, #c01313);
  background-size: 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(255,255,255,0.25);
  -webkit-animation: animate-data-v-45f73ee5 10s ease-in-out infinite;
          animation: animate-data-v-45f73ee5 10s ease-in-out infinite;
}
@-webkit-keyframes animate-data-v-45f73ee5 {
0%{
    background-position: 0 0;
}
25%{
    background-position: 100% 0;
}
50%{
    background-position: 100% 100%;
}
75%{
    background-position: 0% 100%;
}
100%{
    background-position: 0% 0%;
}
}
@keyframes animate-data-v-45f73ee5 {
0%{
    background-position: 0 0;
}
25%{
    background-position: 100% 0;
}
50%{
    background-position: 100% 100%;
}
75%{
    background-position: 0% 100%;
}
100%{
    background-position: 0% 0%;
}
}
.content h4[data-v-45f73ee5]{
  font-size: 1.5em;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000;
  font-size: 2em;
  max-width: 600px;
  position: relative;
}
.content h4[data-v-45f73ee5]:after{
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  text-shadow: 1px 1px 2px rgba(255,255,255,0.4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content p[data-v-45f73ee5]{
  font-size: 1.2em;
  color: #0d0d0d;
}
.content .btns[data-v-45f73ee5]{
  margin: 25px 0;
  display: inline-flex;
}
.content .btns a[data-v-45f73ee5]{
  display: inline-block;
  margin: 0 10px;
  text-decoration: none;
  border: 2px solid #69a6ce;
  color: #69a6ce;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.content .btns a[data-v-45f73ee5]:hover{
  background: #69a6ce;
  color: #fff;
}