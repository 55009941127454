/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
.nav[data-v-84fc96a2]{
 
  display: flex;
  padding: 10px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
  inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
  -5px -5px 5px rgba(255, 255, 255, 0.5);
}
.nav-pills .nav-link.active[data-v-84fc96a2], .nav-pills .show > .nav-link[data-v-84fc96a2]{

  background-color:transparent;
  border-radius: 50px;
  padding: 10px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5),
    -5px -5px 5px rgba(255, 255, 255, 0.5);
}


/* Rounded tabs */
@media (min-width: 576px) {
.rounded-nav[data-v-84fc96a2] {
    border-radius: 50px !important;
}
}
@media (min-width: 576px) {
.rounded-nav .nav-link[data-v-84fc96a2] {
    border-radius: 50px !important;
}
}

